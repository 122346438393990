/**
 * Controller for the map view.
 *
 * @object app.views.map
 */
app.views.map = (function() {

  /**
   * Object with properties and methods for the current view.
   *
   * @object view
   */
  var view = {

    /**
     * The name of the view.
     *
     * @property view.name
     */
    name: "map",

    /**
     * Object to store view state.
     *
     * @property view.state
     */
    state: {
      initialized: false,
      loaded: false,
      data: [],
      animated: false,
      interacted: false,
      event: null,
      marker: null,
      user: null,
      placed: false,
      displaying: false,
      share: "I dodged a bullet by X metres!",
      zoom: config.map.zoom.start,
      panelTiming: false,
      removedText: false,
      moveCoords: null,
      zoomCache: 0,
    },

    /**
     * Object of DOM element references for the UI.
     *
     * @property view.ui
     */
    ui: {},

    /**
     * Method to handle view initialization.
     *
     * @method view.init
     */
    init: function() {

      view.state.initialized = true;

      if (view.state.loaded) {
        view.markers.init();
        view.geolocation.init();
        view.ui.view.classList.add("active");
      }

    },

    /**
     * Method to handle loading the map.
     *
     * @method view.load
     */
    load: function() {

      var isMobile = window.innerWidth < 900;

      this.state.animated = false;
      this.state.interacted = false;

      this.bind();

      this.map = {
        graphics: new ArcGIS.GraphicsLayer(),
        locator: new ArcGIS.Locator(config.map.locator),
      };

      this.map.instance = new ArcGIS.Map({
        basemap: config.map.type,
        layers: [view.map.graphics],
      });

      var minZoom = isMobile ? 10 : 12;

      this.map.view = new ArcGIS.MapView({
        container: "map",
        map: this.map.instance,
        zoom: config.map.zoom.start,
        center: config.map.center,
        ui: config.map.ui,
      });
      this.map.view.constraints.minZoom = minZoom;
      this.map.view.constraints.snapToZoom = config.map.zoom.snap;

      if (isMobile) {
        this.map.view.padding.top = window.innerHeight / 2;
      }

      this.map.share = new ArcGIS.GraphicsLayer();

      this.map.placeholder = new ArcGIS.MapView({
        container: "placeholder",
        map: new ArcGIS.Map({
          basemap: config.map.type,
          layers: [this.map.share],
        }),
        zoom: config.map.zoom.start,
        center: config.map.center,
        ui: config.map.ui,
      });
      this.map.placeholder.constraints.minZoom = minZoom;
      this.map.placeholder.constraints.snapToZoom = config.map.zoom.snap;

      this.state.center = this.map.view.center.clone();

      this.map.view.when(view.on.mapLoad);

      ArcGIS.WatchUtils.watch(this.map.view, "extent", view.on.mapUpdate);

      view.map.view.on("pointer-down", view.pointer.ondown.bind(view));
      view.map.view.on("pointer-move", view.pointer.onmove.bind(view));
      view.map.view.on("pointer-up", view.pointer.onup.bind(view));

      view.map.view.on("drag", function(event) {
        if (!view.state.animated) {
          event.stopPropagation();
        }
      });

      view.map.view.on("mouse-wheel", function(event) {
        if (!view.state.animated) {
          event.stopPropagation();
        }
      });

      view.map.view.on("key-down", function(event) {
        var keyPressed = event.key;
        if (keyPressed.slice(0, 5) === "Arrow" && !view.state.animated) {
          event.stopPropagation();
        }
      });

      view.ui.address.input.value = "";

    },

    /**
     * Method to handle bind handlers to DOM elements and events.
     *
     * @method view.bind
     */
    bind: function() {

      this.ui = {
        view: document.querySelector(".view-map"),
        map: document.getElementById("map"),
        placeholder: document.getElementById("placeholder"),
        layers: document.querySelector(".layers"),
        text: document.querySelector(".text-wrapper"),
        panel: document.querySelector(".panel"),
        close: document.querySelector(".panel .button-close"),
        open: document.querySelector(".panel .button-open"),
        date: document.querySelector(".date span"),
        errors: document.querySelector(".errors"),
        errorClose: document.querySelector(".errors .button-close"),
        share: document.querySelectorAll(".button-share"),
        markers: {
          wrapper: document.querySelector(".markers"),
          all: document.querySelectorAll(".marker-event"),
          active: document.querySelector(".marker-active"),
          user: document.querySelector(".marker-user"),
        },
        address: {
          form: document.querySelector("form[name='form-input']"),
          wrapper: document.querySelector(".input-wrapper"),
          results: document.querySelector(".input-results"),
          input: document.querySelector("input[name='input-address']"),
          search: document.querySelector(".button-search"),
          geolocate: document.querySelector(".button-geolocate"),
          clear: document.querySelector(".button-clear"),
        },
      };

      view.ui.address.search.onclick = view.geocoding.search;
      view.ui.address.results.onclick = view.geocoding.select;
      view.ui.address.input.onkeyup = view.geocoding.input;
      view.ui.address.input.onfocus = view.geocoding.focus;
      view.ui.address.clear.onclick = view.reset;
      view.ui.close.onclick = view.toggle;
      view.ui.open.onclick = view.toggle;

      view.ui.address.form.onsubmit = view.geocoding.search;

      view.ui.errorClose.onclick = function(event) {
        event.preventDefault();
        view.ui.errors.classList.remove("active");
      };

      for (var i = 0; i < this.ui.share.length; i++) {
        this.ui.share[i].onclick = view.share.click;
      }

    },

    /**
     * Object for event handlers.
     *
     * @object view.on
     */
    on: {

      /**
       * Method to handle when the map view loads.
       *
       * @method view.on.mapLoad
       */
      mapLoad: function() {

        view.state.loaded = true;

        window.setTimeout(function() {
          if (view.state.initialized) {
            view.init();
          }
        }, 500); // HACK

      },

      /**
       * Method to handle watching the changes in the map view extent.
       *
       * @method view.on.mapUpdate
       */
      mapUpdate: function(value, previous) {

        if (view.state.animated && !view.state.interacted) {
          if (view.state.panelTiming) {
            view.ui.panel.classList.add("active");
            view.state.panelTiming = false;
          }
          view.state.interacted = true;
          view.markers.populate(view.state.displaying);
        }

        if (view.state.placed) {
          view.markers.set(true);
        }

        if (view.state.displaying) {
          if (!view.state.removedText) {
            var active = document.querySelector(".marker-active");
            if (active) {
              if (view.state.moveCoords === null) {
                view.state.moveCoords = view.map.view.toScreen({
                  x: view.state.event.location[0],
                  y: view.state.event.location[1],
                  spatialReference: { wkid: 4326 },
                });
              }
              var coords = view.map.view.toScreen({
                x: view.state.event.location[0],
                y: view.state.event.location[1],
                spatialReference: { wkid: 4326 },
              });
              var movedX = Math.abs(view.state.moveCoords.x - coords.x) > config.map.distance.move;
              var movedY = Math.abs(view.state.moveCoords.y - coords.y) > config.map.distance.move;
              var movedZ = Math.abs(view.state.zoom - view.map.view.zoom) > 1;
              if (movedX || movedY || movedZ) {
                view.ui.text.classList.remove("active");
                view.state.removedText = true;
                view.state.moveCoords = null;
              }
            }
          }
          view.markers.populate(true);
        }

      },

    },

    /**
     * Object to handle on pointer (mouse/touch) events on map.
     *
     * @object view.pointer
     */
    pointer: {

      /**
       * If the user is pressing on the map.
       *
       * @property view.pointer.pressing
       */
      pressing: false,

      /**
       * The hit area size for markers.
       *
       * @property view.pointer.area
       */
      area: { x: 0, y: 0 },

      /**
       * The duration time placeholder for the press event.
       *
       * @property view.pointer.duration
       */
      duration: 0,

      /**
       * Method to handle on pointer (mouse/touch) down event on map.
       *
       * @method view.pointer.ondown
       */
      ondown: function(event) {

        if (!view.state.animated) {
          return;
        }

        view.pointer.pressing = true;
        view.pointer.duration = Date.now();

        view.pointer.area = {
          x: view.ui.markers.active.offsetWidth,
          y: view.ui.markers.active.offsetHeight,
        };

      },

      /**
       * Method to handle on pointer (mouse/touch) move event on map.
       *
       * @method view.pointer.onmove
       */
      onmove: function(event) {

        if (!view.state.animated) {
          return;
        }

        var area = view.pointer.area;

        view.state.data.forEach(function(entry) {
          var position = view.map.view.toScreen({
            x: entry.location[0],
            y: entry.location[1],
            spatialReference: { wkid: 4326 },
          });
          var withinX = Math.abs(event.x - position.x) < area.x / 2;
          var withinY = event.y < position.y && event.y > position.y - area.y;
          if (!view.pointer.pressing) {
            if (withinX && withinY) {
              if (!entry.hovering) {
                entry.hovering = true;
                view.ui.map.classList.add("hover");
              }
            } else {
              if (entry.hovering) {
                view.ui.map.classList.remove("hover");
                entry.hovering = false;
              }
            }
          }
        });

      },

      /**
       * Method to handle on pointer (mouse/touch) up event on map.
       *
       * @method view.pointer.onup
       */
      onup: function(event) {

        if (!view.state.animated) {
          return;
        }

        view.pointer.pressing = false;

        if (!view.pointer.duration) {
          return;
        }

        var duration = Date.now() - view.pointer.duration;

        if (duration > 500) {
          return;
        }

        var area = view.pointer.area;
        var clicked = false;

        view.state.data.forEach(function(entry) {
          var position = view.map.view.toScreen({
            x: entry.location[0],
            y: entry.location[1],
            spatialReference: { wkid: 4326 },
          });
          var withinX = Math.abs(event.x - position.x) < area.x / 2;
          var withinY = event.y < position.y && event.y > position.y - area.y;
          if (withinX && withinY && !clicked) {
            clicked = true;
            view.state.moveCoords = null;
            return view.markers.click(entry);
          }
        });

      },

    },

    /**
     * Method to handle animating the map view and UI.
     *
     * @method view.animate
     */
    animate: function(eventData, userLocation) {

      view.state.attempts = 0;
      view.state.animated = false;
      view.state.interacted = false;
      view.state.removedText = false;
      view.state.event = eventData;
      view.state.user = userLocation;

      // Clear the results
      view.ui.address.results.innerHTML = "";
      view.ui.address.input.blur();
      view.markers.depopulate();
      view.ui.text.classList.remove("active");

      // Zoom/pan to the location
      view.state.zoom = config.map.zoom.start;
      view.zoom(true);

    },

    /**
     * Method to handle zooming on the map.
     *
     * @method view.zoom
     */
    zoom: function(first, callback) {

      var ideal = window.innerWidth < 900 ? 100 : 200;

      var positionA = view.map.placeholder.toScreen({
        x: view.state.event.location[0],
        y: view.state.event.location[1],
        spatialReference: { wkid: 4326 },
      });

      var positionB = view.map.placeholder.toScreen({
        x: view.state.user[0],
        y: view.state.user[1],
        spatialReference: { wkid: 4326 },
      });

      var a = positionA.x - positionB.x;
      var b = positionA.y - positionB.y;
      var distance = Math.sqrt((a * a) + (b * b));

      if (first === true) {
        view.state.amount = distance > ideal ? -0.01 : 0.01;
      }

      view.state.zoom = view.state.zoom + view.state.amount;

      var next = view.state.amount > 0 ? distance < ideal : distance > ideal;
      var attempts = 1000; // HACK

      if (next && view.state.attempts < attempts) {

        view.state.attempts += 1;

        var target = {
          target: view.state.event.location,
          zoom: view.state.zoom,
        };
        var options = {
          animate: false,
        };

        view.map.placeholder.goTo(target, options).then(
          view.zoom.bind(view, false, callback)
        );

      } else {

        if (callback) {
          window.setTimeout(callback, 500);
        } else {
          view.map.view.goTo({
            target: view.state.event.location,
            zoom: view.map.placeholder.zoom,
          }, { duration: 2000 }).then(view.display);
          view.map.placeholder.goTo({
            target: config.map.center,
            zoom: config.map.zoom.start,
          }, { animate: false });
        }

      }

    },

    /**
     * Method to handle displaying the results on the map.
     *
     * @method view.display
     */
    display: function(skipTimeout) {

      view.state.panelTiming = true;

      window.setTimeout(function() {
        view.state.animated = true;
        view.markers.set();
        view.state.share = [
          "I dodged a bullet, but I won't dodge the issue."
        ].join(" ");
        view.ui.date.innerHTML = view.state.event.date;
        view.ui.address.results.innerHTML = "";
        view.ui.text.querySelector(".text").innerHTML = [
          '<div class="prefix">You dodged a bullet by</div>',
          '<div class="value">', view.state.event.distance, '</div>',
          '<div class="suffix">metres</div>',
        ].join(" ");
        window.setTimeout(function() {
          view.ui.text.classList.add("active");
          window.setTimeout(function() {
            view.ui.panel.classList.add("active");
            view.state.panelTiming = false;
            view.state.removedText = false;
          }, config.delays.panel);
        }, 1000);
      }, skipTimeout ? 0 : config.delays.markers);

      view.share.cacheImage();

    },

    /**
     * Method to handle toggle the panel overlay.
     *
     * @method view.toggle
     */
    toggle: function(event) {

      event.preventDefault();

      view.ui.panel.classList.toggle("closed");

    },

    /**
     * Object to handle map markers.
     *
     * @object view.markers
     */
    markers: {

      /**
       * Method to handle parsing and caching the marker data.
       *
       * @method view.markers.init
       */
      init: function() {

        api.data.forEach(function(entry) {
          var date = new Date(entry.date);
          view.state.data.push({
            location: entry.location,
            date: date.toLocaleString("en-ca", {
              weekday: "long",
              month: "long",
              year: "numeric",
              day: "numeric",
            }),
            distance: -1,
          });
        });

      },

      /**
       * Method to handle clicking the markers on the map.
       *
       * @method view.markers.click
       */
      click: function(entry) {

        if (!view.state.animated) {
          return;
        }

        view.state.event = entry;
        view.ui.text.classList.remove("active");

        var target = { target: view.state.event.location };
        var options = { duration: 500 };

        view.map.view.goTo(target, options).then(view.display.bind(view, true));

      },

      /**
       * Method to handle populating the markers on the map.
       *
       * @method view.markers.populate
       */
      populate: function(update) {

        var html = "";

        view.state.displaying = true;

        view.state.data.forEach(function(entry, i) {
          if (!update) {
            entry.selector = ".marker-event[data-index='" + i + "']";
            html += [
              '<div class="marker marker-event" data-index="', i, '"></div>',
            ].join("");
            return;
          }
          if (!entry.element) {
            entry.element = document.querySelector(entry.selector);
          }
          view.markers.update(entry.element, entry.location, update);
        });

        if (!update) {
          view.ui.markers.wrapper.innerHTML = html;
          view.markers.populate(true);
        }

      },

      /**
       * Method to handle depopulating the markers from the map.
       *
       * @method view.markers.depopulate
       */
      depopulate: function() {

        view.state.displaying = false;

        view.ui.markers.wrapper.innerHTML = "";

        view.state.data.forEach(function(entry, i) {
          entry.element = null;
          entry.selector = null;
        });

        view.ui.text.classList.remove("active");
        view.ui.markers.active.classList.remove("active");
        view.ui.markers.user.classList.remove("active");

      },

      /**
       * Method to handle displaying the markers on the map.
       *
       * @method view.markers.set
       */
      set: function(update) {

        var markers = view.ui.markers;

        view.markers.update(markers.user, view.state.user, update, true);
        view.markers.update(markers.active, view.state.event.location, update, true);

        if (!update) {
          view.state.placed = true;
        }

      },

      /**
       * Method to handle updating the marker.
       *
       * @method view.markers.update
       */
      update: function(marker, location, update, isActive) {

        var position = view.map.view.toScreen({
          x: location[0],
          y: location[1],
          spatialReference: { wkid: 4326 },
        });

        // Don't show/animate when out of view
        if (position.x < -100 || position.x > window.innerWidth + 100
          || position.y < -100 || position.y > window.innerHeight + 100
        ) {
          marker.style.display = "none";
          return;
        }

        if (marker.style.display === "none") {
          marker.style.display = "block";
        }

        var translate = [
          "translate3d(", position.x, "px, ", position.y, "px, 0)"
        ].join("");

        var zoom = view.map.view.zoom;
        var options = config.map.markers;
        var size = 1;

        if (!isActive) {
          var offset = options.zoom.max - options.zoom.min;
          if (zoom > options.zoom.min) {
            marker.classList.remove("dot");
          } else {
            marker.classList.add("dot");
          }
          if (zoom < options.zoom.max && zoom >= options.zoom.min) {
            size = options.size.max - ((options.zoom.max - zoom) / offset);
          }
          if (size > options.size.max) {
            size = options.size.max;
          } else if (size < options.size.min) {
            size = options.size.min;
          }
        }

        var scale = size === 1 ? "" : [
          "scale3d(", size, ",", size, ",", size, ")"
        ].join("");

        marker.style.transform = [translate, scale].join(" ");

        if (!update) {
          marker.classList.add("active");
        }

      },

      /**
       * Method to handle creating a new map marker.
       *
       * @method view.markers.create
       */
      create: function(type, location, active, content) {

        var color = type === "user" ? "cyan" : "#ea452d";
        var icon = type === "user" ? "\ue613" : "\ue61d";
        var size = type === "user" ? 30 : 50;

        return {

          symbol: {
            type: "text",
            color: color,
            text: icon,
            font: {
              size: active ? size : size / 2,
              family: "calcite-web-icons",
            },
          },

          geometry: {
            type: "point",
            longitude: location[0],
            latitude: location[1],
          },

          popupTemplate: content,

        };

      },

    },

    /**
     * Object to handle geocoding addresses.
     *
     * @object view.geocoding
     */
    geocoding: {

      /**
       * Method to handle taking user input and returning suggested addresses.
       *
       * @method view.geocoding.input
       */
      input: function(event) {

        var request = {
          text: event.target.value,
          categories: config.map.locator.categories,
          location: view.state.center,
          distance: config.map.locator.distance,
          countryCode: config.map.locator.countryCode,
        };

        view.ui.address.clear.classList.add("active");

        view.map.locator.suggestLocations(request).then(function(data) {
          var html = "";
          data.forEach(function(result) {
            var address = view.geocoding.format(result.text);
            html += [
              '<li class="input-result" data-id="', result.magicKey, '">',
                address,
              '</li>',
            ].join("");
          });
          view.ui.address.results.innerHTML = html;
        });

      },

      /**
       * Method to handle taking user selection and initiating the animation.
       *
       * @method view.geocoding.result
       */
      select: function(event) {

        event.preventDefault();

        if (event.target.className === "input-geolocate") {
          return;
        }

        var id = event.target.dataset.id;
        var address = { magicKey: id };

        view.ui.address.input.value = event.target.innerHTML;

        view.geocoding.query(address);

      },

      /**
       * Method to handle querying the address.
       *
       * @method view.geocoding.query
       */
      query: function(address) {

        view.map.locator.addressToLocations(address).then(function(data) {
          var point = data[0].location;
          // HACK: Check address:
          view.map.locator.locationToAddress(point).then(function(data) {
            if (view.geolocation.checkAddress(data.attributes)) {
              view.geocoding.success([point.longitude, point.latitude]);
            }
          }).catch(function(errors) {
            console.error("locator.locationToAddress", errors);
          });

        });

      },

      /**
       * Method to handle finding the nearest event and animating to it.
       *
       * @method view.geocoding.success
       */
      success: function(location) {

        var closest = null;

        view.state.data.forEach(function(entry) {
          entry.distance = math.getDistanceBetweenCoords(
            entry.location,
            location,
            true
          );
          if (!closest || closest.distance > entry.distance) {
            closest = entry;
          }
        });

        view.ui.address.wrapper.classList.add("active");

        if (closest.distance > 1500) {
          view.geolocation.errors([
            "<h2>NO SHOOTINGS NEARBY</h2>",
            "<p>According to data from the Toronto Police, there have been no shootings reported between 2004 and 2019 within 1,500 metres of the location entered.<br><br>",
            "However, this is an issue that affects us all. We encourage you to learn more about our solutions to end gun violence across Canada</p>",
            '<a href="https://www.triggerchange.ca/" target="_blank" class="button button-light">Learn More</a>',
          ].join(""));
          return;
        }

        view.animate(closest, location);

      },

      /**
       * Method to handle searching with the current criteria.
       *
       * @method view.geocoding.search
       */
      search: function(event) {

        event.preventDefault();

        var request = {
          text: view.ui.address.input.value,
          categories: config.map.locator.categories,
          location: view.state.center,
          distance: config.map.locator.distance,
          countryCode: config.map.locator.countryCode,
        };

        view.map.locator.suggestLocations(request).then(function(data) {
          if (!data || data.length === 0) {
            return view.geolocation.errors([
              "<h2>Error</h2>",
              "<p>Sorry, we didn't recognise this address.</p>",
              '<a href="https://www.triggerchange.ca/" target="_blank" class="button button-light">Learn More</a>',
            ].join(""));
          }
          var result = data[0];
          var address = view.geocoding.format(result.text);
          view.ui.address.input.value = address;
          view.geocoding.query({  magicKey: result.magicKey });
        });

      },

      /**
       * Method to handle formatting the address name.
       *
       * @method view.geocoding.format
       */
      format: function(address) {

        // address = address.split(", Ontario,")[0]; // HACK
        return address; //.split(", Toronto")[0];

      },

      /**
       * Method to handle on focus.
       *
       * @method view.geocoding.focus
       */
      focus: function(event) {

        if (event.target.value === "" && "geolocation" in window.navigator) {
          view.geocoding.addLocate();
          view.ui.address.clear.classList.add("active");
        }

      },

      /**
       * Method to handle on add locate button.
       *
       * @method view.geocoding.addLocate
       */
      addLocate: function(html) {

        html = [
          '<li class="input-geolocate">',
            '<img src="https://assets.dodgethebullet.ca/images/icon-location.png" alt="Use my location">',
            "Use my current location",
          '</li>',
        ].join("") + (html || "");

        view.ui.address.results.innerHTML = html;

        var button = document.querySelector(".input-geolocate");

        button.onclick = function(event) {
          event.preventDefault();
          view.geolocation.locate();
        };

      },

    },

    /**
     * Object to handle user device geolocation.
     *
     * @object view.geolocation
     */
    geolocation: {

      /**
       * Flag for if the user has clicked the locate button.
       *
       * @property view.geolocation.clicked
       */
      clicked: false,

      /**
       * Method to handle initializing the geolocation functionality.
       *
       * @method view.geolocation.init
       */
      init: function() {

        if (!("geolocation" in window.navigator)) {
          return console.warn("device location is not supported or permitted");
        }

      },

      /**
       * Method to handle initializing the geolocation functionality.
       *
       * @method view.geolocation.locate
       */
      locate: function(event) {

        if (event && event.preventDefault) {
          view.geolocation.clicked = true;
          event.preventDefault();
        }

        if (!("geolocation" in window.navigator)) {
          return console.warn("device location is not supported or permitted");
        }

        window.navigator.geolocation.getCurrentPosition(
          view.geolocation.success,
          view.geolocation.fail
        );

      },

      /**
       * Method to handle successful return of device location.
       *
       * @method view.geolocation.success
       */
      success: function(position) {

        console.log("geolocation", position.coords);

        var location = [position.coords.longitude, position.coords.latitude];
        var point = new ArcGIS.Point(position.coords);

        view.geolocation.clicked = false;

        view.map.locator.locationToAddress(point).then(function(data) {
          if (view.geolocation.checkAddress(data.attributes)) {
            view.ui.address.input.value = data.address;
            view.ui.address.clear.classList.add("active");
            view.geocoding.success(location);
          }
        }).catch(function(errors) {
          console.error("locator.locationToAddress", errors);
        });

      },

      /**
       * Method to handle checking the address distance.
       *
       * @method view.geolocation.checkAddress
       */
      checkAddress: function(attributes) {

        var body = "";

        if (attributes.CountryCode !== "CAN") {
          body = [
            "<h2>DATA NOT AVAILABLE</h2>",
            "<p>At this time, our algorithm is confined to the Greater Toronto Area in Canada. However, this is an issue that affects us all. We urge you to speak out against gun violence in your community.</p>"
          ].join("");
        } else if (attributes.Region !== "Ontario") {
          body = [
            "<h2>DATA NOT AVAILABLE</h2>",
            "<p>At this time, our algorithm is confined to the Greater Toronto Area city limits. However, this is an issue that affects us all. We encourage you to learn more about our solutions to end gun violence across Canada.</p>",
            '<a href="https://www.triggerchange.ca/" target="_blank" class="button button-light">Learn More</a>',
          ].join("");
        }

        if (body === "") {
          return true;
        }

        view.geolocation.errors(body);

        return false;

      },

      /**
       * Method to handle errors returned from device location.
       *
       * @method view.geolocation.errors
       */
      errors: function(body) {

        view.reset();
        view.ui.panel.classList.remove("active");
        view.ui.errors.querySelector("div").innerHTML = body;
        view.ui.errors.classList.add("active");

      },

      /**
       * Method to handle errors returned from device location.
       *
       * @method view.geolocation.fail
       */
      fail: function(errors) {

        console.error("geolocation", errors);

        view.geolocation.errors([
          "<h2>SORRY, WE CANNOT AUTO-LOCATE YOU</h2>",
          "<p>Please check that your device's Location Services are turned on to use this feature. Alternatively, please type in an address into search field to continue.</p>",
        ].join(""));

        view.geolocation.clicked = false;

      },

    },

    /**
     * Method to handle drawing the geographic boundaries.
     *
     * @method view.boundaries
     */
    boundaries: function() {

      var symbol = {
        type: "simple-fill",
        color: [0, 0, 0, 0.5],
        style: "solid",
        outline: {
          color: [0, 0, 0, 0.75],
          width: 2,
        },
      };

      var boundary = new app.classes.Graphic({
        geometry: {
          type: "polyline",
          paths: config.map.boundaries.paths,
        },
        symbol: symbol,
      });

      app.graphics.add(boundary);

    },

    /**
     * Object to handle social sharing.
     *
     * @object view.share
     */
    share: {

      /**
       * The canvas element to render on.
       *
       * @property view.share.canvas
       */
      canvas: null,

      /**
       * The canvas context reference.
       *
       * @property view.share.context
       */
      context: null,

      /**
       * Object to handle text related values.
       *
       * @object view.share.text
       */
      text: {
        left: 50,
        top: 150,
      },

      /**
       * Object to handle coordinate values.
       *
       * @object view.share.coords
       */
      coords: {
        event: { x: 0, y: 0 },
        user: { x: 0, y: 0 },
      },

      /**
       * Method to handle sharing on social media networks.
       *
       * @method view.share.click
       */
      click: function(event) {

        if (event) {
          event.preventDefault();
        }

        switch (event.target.dataset.network) {
          case "facebook":
            return view.share.saveImage("facebook");
          case "twitter":
            return view.share.saveImage("twitter");
          case "link":
            return view.share.copyLink();
        }

      },

      /**
       * Method to handle copying the URL to the clipboard.
       *
       * @method view.share.copyLink
       */
      copyLink: function() {

        var button = document.querySelector(".button-share[data-network='link']");
        var element = document.createElement("textarea");
        var ios = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        element.textContent = config.share.url;
        element.value = config.share.url;
        element.style.position = "absolute";
        element.style.top = "-123rem;";

        if (ios) {
          element.readOnly = false;
        }

        app.ui.root.appendChild(element);

        try {
          // HACK: Check for iOS and use additional method
          if (ios) {
            view.share.copyLinkSelection(element);
          } else {
            element.select();
          }
          document.execCommand("copy");
          button.classList.add("active");
          window.setTimeout(function() {
            button.classList.remove("active");
          }, 2000);
        } catch (exception) {
          console.warn("share.link", "error", exception);
        } finally {
          return app.ui.root.removeChild(element);
        }

      },

      /**
       * Method to handle copying the URL to the clipboard using range selection.
       *
       * @method view.share.copyLinkSelection
       */
      copyLinkSelection: function(element) {

        element.contentEditable = true;

        var range = document.createRange();
        range.selectNodeContents(element);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        element.setSelectionRange(0, 1000);

      },

      /**
       * Method to handle caching the image to share.
       *
       * @method view.share.cacheImage
       */
      cacheImage: function() {

        var share = view.share;

        view.state.zoomCache = view.state.zoom;

        share.text.left = 50;
        share.text.top = 150;
        share.canvas = document.createElement("canvas");
        share.context = share.canvas.getContext("2d");

        share.canvas.width = config.share.size.width;
        share.canvas.height = config.share.size.height;
        share.context.filter = config.share.filter.start;

        var target = {
          target: view.state.event.location,
          zoom: view.state.zoom,
        };
        var options = {
          animate: false,
        };

        view.ui.placeholder.classList.add("share");

        view.map.placeholder.padding.bottom = 100;
        view.map.placeholder.padding.right = 300;

        window.setTimeout(view.share.zoomCanvas, 10);

      },

      /**
       * Method to handle zooming the canvas.
       *
       * @method view.share.zoomCanvas
       */
      zoomCanvas: function() {

        view.state.attempts = 0;
        view.state.zoom = config.map.zoom.start;

        view.map.placeholder.goTo({
          target: view.state.event.location,
          zoom: view.state.zoom,
        }, { animate: false }).then(function() {

          view.zoom(true, function() {

            view.map.placeholder.goTo({
              target: view.state.event.location,
              zoom: view.state.zoom - 1,
            }, { animate: false }).then(function() {

              window.setTimeout(view.share.prepareCanvas, 500);

            });

          });

        });

      },

      /**
       * Method to handle preparing the canvas.
       *
       * @method view.share.prepareCanvas
       */
      prepareCanvas: function() {

        view.share.coords.event = view.map.placeholder.toScreen({
          x: view.state.event.location[0],
          y: view.state.event.location[1],
          spatialReference: { wkid: 4326 },
        });

        view.share.coords.user = view.map.placeholder.toScreen({
          x: view.state.user[0],
          y: view.state.user[1],
          spatialReference: { wkid: 4326 },
        });

        view.map.placeholder.takeScreenshot(config.share.size).then(function(image) {
          view.share.loadImage(image.dataUrl, view.share.drawLayers);
        });

      },

      /**
       * Method to handle loading an image.
       *
       * @method view.share.loadImage
       */
      loadImage: function(src, callback) {

        var image = document.createElement("img");
        image.crossOrigin = "anonymous";
        image.onload = callback.bind(view, image);
        image.src = src;

      },

      /**
       * Method to handle drawing the content layers on the canvas.
       *
       * @method view.share.drawLayers
       */
      drawLayers: function(image) {

        var share = view.share;

        share.context.drawImage(image, 0, 0);

        share.context.fillStyle = "rgba(0, 0, 0, 0.5)";
        share.context.fillRect(0, 0, share.canvas.width, share.canvas.height);

        share.context.filter = config.share.filter.end;

        view.share.drawText();

        view.share.loadImage(
          config.images.markers.event,
          view.share.drawMarkerEvent
        );

      },

      /**
       * Method to handle drawing the text lockup on the canvas.
       *
       * @method view.share.drawText
       */
      drawText: function() {

        var size = 90;

        view.share.drawTextLine(
          "I DODGED A",
          size
        );

        view.share.drawTextLine(
          "BULLET BY",
          Math.floor(size * 1.045)
        );

        view.share.drawTextLine(
          view.state.event.distance,
          Math.floor(size * 2.945),
          Math.floor(size * 1.278),
          null,
          true
        );

        view.share.drawTextLine("METRES",
          Math.floor(size * 1.389),
          -Math.floor(size / 2.55)
        );

      },

      /**
       * Method to handle drawing a line of text on the canvas.
       *
       * @method view.share.drawTextLine
       */
      drawTextLine: function(text, size, offset, style, isNumber) {

        if (!text) return;

        text = text.toString().toUpperCase();
        size = size || 90;
        offset = offset || 0;
        style = style || "rgba(255, 255, 255, 1)";

        var top = view.share.text.top + offset;
        var left = view.share.text.left;
        var length = 325;

        view.share.context.font = size + "px bebas-regular";
        view.share.context.fillStyle = style;

        if (isNumber) {
          var width = view.share.context.measureText(text).width;
          if (width < length) {
            left += (length - width) / 2;
          }
        }

        view.share.context.fillText(text, left, top);

        view.share.text.top += size - 10;

      },

      /**
       * Method to handle drawing the event marker on the canvas.
       *
       * @method view.share.drawMarkerEvent
       */
      drawMarkerEvent: function(image) {

        var width = image.width;
        var height = width * (image.height / image.width);
        var left = view.share.coords.event.x - (width / 2) + 150;
        var top = view.share.coords.event.y - height + 50;

        view.share.context.drawImage(image, left, top); // width, height

        view.share.loadImage(
          config.images.markers.user,
          view.share.drawMarkerUser
        );

      },

      /**
       * Method to handle drawing the user marker on the canvas.
       *
       * @method view.share.drawMarkerUser
       */
      drawMarkerUser: function(image) {

        view.share.context.drawImage(
          image,
          view.share.coords.user.x - (image.width / 2) + 150,
          view.share.coords.user.y - (image.height / 2) + 50
          // marker.width * 0.8,
          // marker.height * 0.8
        );

        view.share.cached = view.share.canvas.toDataURL("image/jpeg", 0.9);

        view.state.zoom = view.state.zoomCache;

      },

      /**
       * Method to handle converting Base64 data into a Blob.
       *
       * @method view.share.convertBase64toBlobURL
       */
      convertBase64toBlobURL: function(b64Data, contentType, sliceSize) {

        b64Data = b64Data.split("base64,")[1];
        sliceSize = sliceSize || 512;

        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {

          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          byteArrays.push(new Uint8Array(byteNumbers));

        }

        var blob = new Blob(byteArrays, { type: "image/jpeg" });

        return window.URL.createObjectURL(blob);

      },

      /**
       * Method to handle saving the image to the server.
       *
       * @method view.share.saveImage
       */
      saveImage: function(network) {

        var options = {
          url: "https://api.dodgethebullet.ca/create",
          method: "POST",
          type: "image/base64",
          data: view.share.cached.split("base64,")[1],
        };

        var selector = ".button-share[data-network='" + network + "']";
        var element = document.querySelector(selector);
        element.classList.add("active");

        view.share.sendImage(options, function(success, data) {

          if (!success) {
            return console.log("error", data);
          }

          var link = window.encodeURIComponent(data.file);
          var url;

          if (network === "twitter") {
            url = [
              "https://twitter.com/intent/tweet",
              "?url=", link,
              "&text=", view.state.share,
            ].join("");
          } else {
            url = [
              "https://www.facebook.com/sharer.php",
              "?u=", link,
            ].join("");
          }

          if (!window.open(url, "_blank")) {
            window.location.href = url;
          }

          view.map.placeholder.goTo({
            target: config.map.center,
            zoom: config.map.zoom.start,
          }, { animate: false });

          window.setTimeout(function() {
            element.classList.remove("active");
          }, 500);

        });

      },

      /**
       * Method to handle saving the image to the server.
       *
       * @method view.share.saveImage
       */
      sendImage: function(options, callback) {

        var request = new XMLHttpRequest();

        options.method = options.method || "POST";
        options.type = options.type || "application/json";
        options.data = options.data || {};
        options.headers = options.headers || {};

        if (options.type === "application/json") {
          options.data = JSON.stringify(options.data);
        }

        // Open the request to the API server
        request.open(options.method, options.url);

        // Handle the request state change
        request.onreadystatechange = function() {

          if (request.readyState !== XMLHttpRequest.DONE) return;

          var success = request.status === 200 || request.status === 204;
          var response = request.responseText;
          var data = {};

          try { data = JSON.parse(response); } catch(errors) { }

          if (!success || data.errors) {
            return callback && callback(false, data);
          }

          callback && callback(true, data);

        };

        // Set the correct content type
        request.setRequestHeader("Content-type", options.type);

        // Set custom headers
        if (options.headers) {
          for (var key in options.headers) {
            request.setRequestHeader(key, options.headers[key]);
          }
        }

        // Make the request
        request.send(options.data);

      },

    },

    /**
     * Method to handle resetting the map view and UI.
     *
     * @method view.reset
     */
    reset: function(event) {

      if (event) {
        event.preventDefault();
      }

      view.markers.depopulate();

      view.ui.panel.classList.remove("active");
      view.ui.address.wrapper.classList.remove("active");
      view.ui.address.clear.classList.remove("active");
      view.ui.address.input.value = "";
      view.ui.address.input.focus();
      view.ui.address.results.innerHTML = "";

      window.setTimeout(function() {
        view.ui.panel.classList.remove("closed");
      }, 1000);

      view.state.animated = false;
      view.state.interacted = false;
      view.state.displaying = false;
      view.state.placed = false;

      var options = { duration: 1000 };

      view.map.view.goTo({
        target: config.map.center,
        zoom: config.map.zoom.start,
      }, options);

    },

  };

  // Return the view's public controller
  return view;

})();
