/**
 * Controller for the index view.
 *
 * @object app.views.index
 */
app.views.index = (function() {

  /**
   * Object with properties and methods for the current view.
   *
   * @object view
   */
  var view = {

    /**
     * The name of the view.
     *
     * @property view.name
     */
    name: "index",

    /**
     * Object to store view state.
     *
     * @property view.state
     */
    state: {},

    /**
     * Object of DOM element references for the UI.
     *
     * @property view.ui
     */
    ui: {},

    /**
     * Method to handle view initialization.
     *
     * @method view.init
     */
    init: function() {

      view.bind();
      view.video();

      window.setTimeout(function() {
        view.ui.view.classList.add("active");
      }, 50); // HACK

    },

    /**
    * Method to handle bind handlers to DOM elements and events.
    *
    * @method view.bind
    */
    bind: function() {

      view.ui = {
        view: document.querySelector(".view-index"),
        cta: document.querySelector(".view-content a"),
        video: document.querySelector(".video"),
      };

      view.ui.cta.onclick = view.proceed;

    },

    /**
     * Method to handle setting the correct background video.
     *
     * @method view.video
     */
    video: function() {

      var width = window.innerWidth;
      var size = "large";

      if (width <= 480) {
        size = "small";
      } else if (width <= 1024) {
        size = "medium";
      }

      view.ui.video.innerHTML = [
        '<source src="https://assets.dodgethebullet.ca/videos/intro-', size, '.mp4" type="video/mp4">',
      ].join("");

    },

    /**
     * Method to handle proceeding to the next view.
     *
     * @method view.proceed
     */
    proceed: function(event) {

      event.preventDefault();

      view.ui.view.classList.remove("active");

      window.setTimeout(function() {
        router.route("/map");
      }, 250);

    },

  };

  // Return the view's public controller
  return view;

})();
