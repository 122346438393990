/**
 * Module to handle event dispatching and listening.
 *
 * @module Events
 */
var Events = (function() {

  function Events() {
    this.listeners = {};
  };

  // Method to listen for events
  Events.prototype.on = function(name, callback) {
    if (this.listeners[name]) {
      if (this.listeners[name].indexOf(callback) < 0) {
        this.listeners[name].push(callback);
      }
    } else {
      this.listeners[name] = [callback];
    }
  };

  // Method to trigger events
  Events.prototype.emit = function(name, data) {
    var listeners = this.listeners[name];
    if (listeners) {
      listeners.forEach(function(listener) {
        listener(data);
      });
    }
  };

  Events.global = new Events();

  return Events;

})();
