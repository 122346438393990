var api = {
  data: [
    {
      "date": "2004-01-08T05:00:00.000Z",
      "location": [-79.2349625, 43.7815361]
    },
    {
      "date": "2004-01-08T05:00:00.000Z",
      "location": [-79.2068939, 43.8108597]
    },
    {
      "date": "2004-01-25T05:00:00.000Z",
      "location": [-79.4344025, 43.670475]
    },
    {
      "date": "2004-01-25T05:00:00.000Z",
      "location": [-79.2038651, 43.8235435]
    },
    {
      "date": "2004-02-21T05:00:00.000Z",
      "location": [-79.5089798, 43.7223663]
    },
    {
      "date": "2004-03-03T05:00:00.000Z",
      "location": [-79.2276001, 43.8162689]
    },
    {
      "date": "2004-03-04T05:00:00.000Z",
      "location": [-79.2867279, 43.7000847]
    },
    {
      "date": "2004-03-03T05:00:00.000Z",
      "location": [-79.2665024, 43.8080292]
    },
    {
      "date": "2004-04-13T04:00:00.000Z",
      "location": [-79.5505676, 43.7250977]
    },
    {
      "date": "2004-05-01T04:00:00.000Z",
      "location": [-79.5798798, 43.7350807]
    },
    {
      "date": "2004-03-03T05:00:00.000Z",
      "location": [-79.3287811, 43.6959457]
    },
    {
      "date": "2004-05-15T04:00:00.000Z",
      "location": [-79.5187607, 43.769165]
    },
    {
      "date": "2004-05-21T04:00:00.000Z",
      "location": [-79.362999, 43.6600876]
    },
    {
      "date": "2004-06-04T04:00:00.000Z",
      "location": [-79.4281311, 43.6359825]
    },
    {
      "date": "2004-06-19T04:00:00.000Z",
      "location": [-79.5351791, 43.7112045]
    },
    {
      "date": "2004-07-01T04:00:00.000Z",
      "location": [-79.5226669, 43.7721443]
    },
    {
      "date": "2004-07-21T04:00:00.000Z",
      "location": [-79.3563385, 43.8008614]
    },
    {
      "date": "2004-07-24T04:00:00.000Z",
      "location": [-79.3666916, 43.6506844]
    },
    {
      "date": "2004-08-30T04:00:00.000Z",
      "location": [-79.1577835, 43.7938499]
    },
    {
      "date": "2004-09-05T04:00:00.000Z",
      "location": [-79.5614395, 43.7006416]
    },
    {
      "date": "2004-09-21T04:00:00.000Z",
      "location": [-79.6142731, 43.732193]
    },
    {
      "date": "2004-10-15T04:00:00.000Z",
      "location": [-79.5005493, 43.7112694]
    },
    {
      "date": "2004-12-19T05:00:00.000Z",
      "location": [-79.3788757, 43.6606712]
    },
    {
      "date": "2004-12-19T05:00:00.000Z",
      "location": [-79.2565155, 43.730751]
    },
    {
      "date": "2004-12-20T05:00:00.000Z",
      "location": [-79.3292923, 43.7122536]
    },
    {
      "date": "2004-05-22T04:00:00.000Z",
      "location": [-79.4794617, 43.7147217]
    },
    {
      "date": "2005-01-21T05:00:00.000Z",
      "location": [-79.4077148, 43.64291]
    },
    {
      "date": "2005-01-21T05:00:00.000Z",
      "location": [-79.4077148, 43.64291]
    },
    {
      "date": "2005-02-11T05:00:00.000Z",
      "location": [-79.2822571, 43.7694244]
    },
    {
      "date": "2005-02-12T05:00:00.000Z",
      "location": [-79.5870972, 43.7536201]
    },
    {
      "date": "2005-02-15T05:00:00.000Z",
      "location": [-79.2822571, 43.7694244]
    },
    {
      "date": "2005-02-15T05:00:00.000Z",
      "location": [-79.3737793, 43.6672974]
    },
    {
      "date": "2005-02-28T05:00:00.000Z",
      "location": [-79.4460526, 43.7859192]
    },
    {
      "date": "2005-03-05T05:00:00.000Z",
      "location": [-79.356308, 43.7409859]
    },
    {
      "date": "2005-03-25T05:00:00.000Z",
      "location": [-79.3793716, 43.6618767]
    },
    {
      "date": "2005-04-13T04:00:00.000Z",
      "location": [-79.2812576, 43.6853142]
    },
    {
      "date": "2005-04-18T04:00:00.000Z",
      "location": [-79.4360352, 43.6371803]
    },
    {
      "date": "2005-04-24T04:00:00.000Z",
      "location": [-79.5529861, 43.751442]
    },
    {
      "date": "2005-05-05T04:00:00.000Z",
      "location": [-79.4353638, 43.6598854]
    },
    {
      "date": "2005-05-08T04:00:00.000Z",
      "location": [-79.341774, 43.6795692]
    },
    {
      "date": "2005-05-14T04:00:00.000Z",
      "location": [-79.3816376, 43.6673737]
    },
    {
      "date": "2005-05-15T04:00:00.000Z",
      "location": [-79.2416306, 43.7841072]
    },
    {
      "date": "2005-05-18T04:00:00.000Z",
      "location": [-79.1930542, 43.7581673]
    },
    {
      "date": "2005-06-12T04:00:00.000Z",
      "location": [-79.2949219, 43.6897202]
    },
    {
      "date": "2005-06-24T04:00:00.000Z",
      "location": [-79.5218735, 43.7667732]
    },
    {
      "date": "2005-06-29T04:00:00.000Z",
      "location": [-79.4381866, 43.6664314]
    },
    {
      "date": "2005-07-06T04:00:00.000Z",
      "location": [-79.5686188, 43.7462502]
    },
    {
      "date": "2005-07-19T04:00:00.000Z",
      "location": [-79.4829025, 43.6788063]
    },
    {
      "date": "2005-07-30T04:00:00.000Z",
      "location": [-79.4410248, 43.7201309]
    },
    {
      "date": "2005-07-30T04:00:00.000Z",
      "location": [-79.3157654, 43.7967758]
    },
    {
      "date": "2012-01-27T05:00:00.000Z",
      "location": [-79.4373016, 43.6542778]
    },
    {
      "date": "2012-02-20T05:00:00.000Z",
      "location": [-79.4254227, 43.7574692]
    },
    {
      "date": "2012-02-20T05:00:00.000Z",
      "location": [-79.4254227, 43.7574692]
    },
    {
      "date": "2010-07-02T04:00:00.000Z",
      "location": [-79.4864502, 43.7447815]
    },
    {
      "date": "2012-04-12T04:00:00.000Z",
      "location": [-79.4876251, 43.6727638]
    },
    {
      "date": "2012-05-04T04:00:00.000Z",
      "location": [-79.441864, 43.7226105]
    },
    {
      "date": "2012-05-09T04:00:00.000Z",
      "location": [-79.4033203, 43.6979294]
    },
    {
      "date": "2012-05-10T04:00:00.000Z",
      "location": [-79.501503, 43.6648865]
    },
    {
      "date": "2012-05-31T04:00:00.000Z",
      "location": [-79.1552658, 43.7864952]
    },
    {
      "date": "2012-06-02T04:00:00.000Z",
      "location": [-79.3799744, 43.6540642]
    },
    {
      "date": "2012-06-02T04:00:00.000Z",
      "location": [-79.3799744, 43.6540642]
    },
    {
      "date": "2012-06-18T04:00:00.000Z",
      "location": [-79.4183273, 43.6552467]
    },
    {
      "date": "2012-06-22T04:00:00.000Z",
      "location": [-79.2822571, 43.7694244]
    },
    {
      "date": "2012-06-23T04:00:00.000Z",
      "location": [-79.4065704, 43.7565041]
    },
    {
      "date": "2012-07-05T04:00:00.000Z",
      "location": [-79.5583496, 43.6490707]
    },
    {
      "date": "2012-07-16T04:00:00.000Z",
      "location": [-79.1848831, 43.7648697]
    },
    {
      "date": "2012-07-16T04:00:00.000Z",
      "location": [-79.1848831, 43.7648697]
    },
    {
      "date": "2012-07-17T04:00:00.000Z",
      "location": [-79.5042191, 43.6843834]
    },
    {
      "date": "2012-07-19T04:00:00.000Z",
      "location": [-79.4439087, 43.7194862]
    },
    {
      "date": "2012-08-01T04:00:00.000Z",
      "location": [-79.5147705, 43.7672119]
    },
    {
      "date": "2012-09-08T04:00:00.000Z",
      "location": [-79.2065353, 43.8083572]
    },
    {
      "date": "2012-09-08T04:00:00.000Z",
      "location": [-79.298027, 43.6788864]
    },
    {
      "date": "2012-09-16T04:00:00.000Z",
      "location": [-79.5151291, 43.747097]
    },
    {
      "date": "2012-09-18T04:00:00.000Z",
      "location": [-79.5826111, 43.7339706]
    },
    {
      "date": "2012-09-18T04:00:00.000Z",
      "location": [-79.5826111, 43.7339706]
    },
    {
      "date": "2012-10-11T04:00:00.000Z",
      "location": [-79.5154648, 43.7578697]
    },
    {
      "date": "2012-10-28T04:00:00.000Z",
      "location": [-79.213562, 43.7858925]
    },
    {
      "date": "2012-10-29T04:00:00.000Z",
      "location": [-79.4493713, 43.7208595]
    },
    {
      "date": "2012-10-30T04:00:00.000Z",
      "location": [-79.4931412, 43.6883087]
    },
    {
      "date": "2012-11-11T05:00:00.000Z",
      "location": [-79.406517, 43.656723]
    },
    {
      "date": "2012-12-02T05:00:00.000Z",
      "location": [-79.4837265, 43.7335815]
    },
    {
      "date": "2012-11-29T05:00:00.000Z",
      "location": [-79.582756, 43.6332703]
    },
    {
      "date": "2010-07-06T04:00:00.000Z",
      "location": [-79.2666626, 43.7559013]
    },
    {
      "date": "2010-07-10T04:00:00.000Z",
      "location": [-79.397789, 43.6522598]
    },
    {
      "date": "2010-07-13T04:00:00.000Z",
      "location": [-79.3296509, 43.715313]
    },
    {
      "date": "2010-07-16T04:00:00.000Z",
      "location": [-79.2591248, 43.7874107]
    },
    {
      "date": "2010-07-30T04:00:00.000Z",
      "location": [-79.5068817, 43.7403259]
    },
    {
      "date": "2010-07-30T04:00:00.000Z",
      "location": [-79.6118164, 43.7316895]
    },
    {
      "date": "2010-08-14T04:00:00.000Z",
      "location": [-79.5305557, 43.6467743]
    },
    {
      "date": "2010-08-14T04:00:00.000Z",
      "location": [-79.3744507, 43.6688728]
    },
    {
      "date": "2010-08-24T04:00:00.000Z",
      "location": [-79.441864, 43.7226105]
    },
    {
      "date": "2010-09-10T04:00:00.000Z",
      "location": [-79.2320251, 43.7593193]
    },
    {
      "date": "2010-09-12T04:00:00.000Z",
      "location": [-79.5188217, 43.7619209]
    },
    {
      "date": "2010-09-29T04:00:00.000Z",
      "location": [-79.5153656, 43.6998215]
    },
    {
      "date": "2010-09-29T04:00:00.000Z",
      "location": [-79.5153656, 43.6998215]
    },
    {
      "date": "2010-10-04T04:00:00.000Z",
      "location": [-79.4333191, 43.6337547]
    },
    {
      "date": "2010-10-04T04:00:00.000Z",
      "location": [-79.5154648, 43.7578697]
    },
    {
      "date": "2010-10-09T04:00:00.000Z",
      "location": [-79.3590775, 43.6603432]
    },
    {
      "date": "2010-10-09T04:00:00.000Z",
      "location": [-79.3590775, 43.6603432]
    },
    {
      "date": "2010-10-17T04:00:00.000Z",
      "location": [-79.2769928, 43.7024002]
    },
    {
      "date": "2010-10-25T04:00:00.000Z",
      "location": [-79.3627014, 43.6574554]
    },
    {
      "date": "2010-11-17T05:00:00.000Z",
      "location": [-79.4145813, 43.7717056]
    },
    {
      "date": "2010-11-20T05:00:00.000Z",
      "location": [-79.3530273, 43.7873497]
    },
    {
      "date": "2010-11-22T05:00:00.000Z",
      "location": [-79.5034027, 43.7410774]
    },
    {
      "date": "2010-11-24T05:00:00.000Z",
      "location": [-79.2320251, 43.7593193]
    },
    {
      "date": "2010-11-29T05:00:00.000Z",
      "location": [-79.3581619, 43.6612015]
    },
    {
      "date": "2010-12-25T05:00:00.000Z",
      "location": [-79.4937515, 43.6716728]
    },
    {
      "date": "2013-01-18T05:00:00.000Z",
      "location": [-79.3590775, 43.6603432]
    },
    {
      "date": "2005-07-31T04:00:00.000Z",
      "location": [-79.3813705, 43.6574173]
    },
    {
      "date": "2005-08-03T04:00:00.000Z",
      "location": [-79.5826111, 43.7339706]
    },
    {
      "date": "2005-08-05T04:00:00.000Z",
      "location": [-79.3331299, 43.7981796]
    },
    {
      "date": "2005-08-07T04:00:00.000Z",
      "location": [-79.3755188, 43.665741]
    },
    {
      "date": "2005-08-07T04:00:00.000Z",
      "location": [-79.3755188, 43.665741]
    },
    {
      "date": "2005-08-13T04:00:00.000Z",
      "location": [-79.5205917, 43.6958237]
    },
    {
      "date": "2005-08-15T04:00:00.000Z",
      "location": [-79.3746796, 43.6670952]
    },
    {
      "date": "2005-08-16T04:00:00.000Z",
      "location": [-79.3349533, 43.7187347]
    },
    {
      "date": "2005-08-25T04:00:00.000Z",
      "location": [-79.2205582, 43.742836]
    },
    {
      "date": "2005-08-30T04:00:00.000Z",
      "location": [-79.5095215, 43.7391281]
    },
    {
      "date": "2005-09-08T04:00:00.000Z",
      "location": [-79.5022736, 43.7049217]
    },
    {
      "date": "2005-09-09T04:00:00.000Z",
      "location": [-79.3992004, 43.6352386]
    },
    {
      "date": "2005-09-10T04:00:00.000Z",
      "location": [-79.5147705, 43.7672119]
    },
    {
      "date": "2005-09-13T04:00:00.000Z",
      "location": [-79.5163422, 43.7518959]
    },
    {
      "date": "2005-09-16T04:00:00.000Z",
      "location": [-79.4347229, 43.6374626]
    },
    {
      "date": "2005-09-16T04:00:00.000Z",
      "location": [-79.5493622, 43.7272911]
    },
    {
      "date": "2005-09-16T04:00:00.000Z",
      "location": [-79.5493622, 43.7272911]
    },
    {
      "date": "2005-09-16T04:00:00.000Z",
      "location": [-79.5493622, 43.7272911]
    },
    {
      "date": "2005-10-22T04:00:00.000Z",
      "location": [-79.5885315, 43.7386971]
    },
    {
      "date": "2005-10-23T04:00:00.000Z",
      "location": [-79.5917053, 43.7367744]
    },
    {
      "date": "2005-10-23T04:00:00.000Z",
      "location": [-79.5917053, 43.7367744]
    },
    {
      "date": "2005-11-01T05:00:00.000Z",
      "location": [-79.3331299, 43.7981796]
    },
    {
      "date": "2005-11-09T05:00:00.000Z",
      "location": [-79.4427948, 43.6973038]
    },
    {
      "date": "2005-11-14T05:00:00.000Z",
      "location": [-79.4636307, 43.6807251]
    },
    {
      "date": "2005-11-18T05:00:00.000Z",
      "location": [-79.5933151, 43.741787]
    },
    {
      "date": "2005-11-29T05:00:00.000Z",
      "location": [-79.4486847, 43.6595802]
    },
    {
      "date": "2005-12-01T05:00:00.000Z",
      "location": [-79.5438232, 43.7729378]
    },
    {
      "date": "2005-12-23T05:00:00.000Z",
      "location": [-79.5166931, 43.7518578]
    },
    {
      "date": "2005-12-26T05:00:00.000Z",
      "location": [-79.318222, 43.6845245]
    },
    {
      "date": "2006-01-01T05:00:00.000Z",
      "location": [-79.4476852, 43.6962624]
    },
    {
      "date": "2006-02-06T05:00:00.000Z",
      "location": [-79.5017548, 43.7450676]
    },
    {
      "date": "2006-03-12T05:00:00.000Z",
      "location": [-79.5452271, 43.7478828]
    },
    {
      "date": "2006-03-14T05:00:00.000Z",
      "location": [-79.2189102, 43.7431831]
    },
    {
      "date": "2006-03-20T05:00:00.000Z",
      "location": [-79.3737793, 43.6672974]
    },
    {
      "date": "2006-03-27T05:00:00.000Z",
      "location": [-79.4909134, 43.6875725]
    },
    {
      "date": "2006-03-27T05:00:00.000Z",
      "location": [-79.5071945, 43.7484741]
    },
    {
      "date": "2006-04-29T04:00:00.000Z",
      "location": [-79.3285294, 43.7190323]
    },
    {
      "date": "2006-05-28T04:00:00.000Z",
      "location": [-79.215683, 43.7605743]
    },
    {
      "date": "2006-06-03T04:00:00.000Z",
      "location": [-79.5065842, 43.6956444]
    },
    {
      "date": "2006-06-12T04:00:00.000Z",
      "location": [-79.5023117, 43.7165451]
    },
    {
      "date": "2006-06-23T04:00:00.000Z",
      "location": [-79.2244797, 43.8025093]
    },
    {
      "date": "2006-06-26T04:00:00.000Z",
      "location": [-79.3685532, 43.6472855]
    },
    {
      "date": "2006-07-02T04:00:00.000Z",
      "location": [-79.4740753, 43.6789017]
    },
    {
      "date": "2006-07-05T04:00:00.000Z",
      "location": [-79.5448608, 43.600853]
    },
    {
      "date": "2006-07-11T04:00:00.000Z",
      "location": [-79.3764572, 43.6548309]
    },
    {
      "date": "2006-07-12T04:00:00.000Z",
      "location": [-79.2264557, 43.8298149]
    },
    {
      "date": "2006-07-12T04:00:00.000Z",
      "location": [-79.4920197, 43.6879234]
    },
    {
      "date": "2006-07-17T04:00:00.000Z",
      "location": [-79.3106232, 43.7138062]
    },
    {
      "date": "2006-09-14T04:00:00.000Z",
      "location": [-79.3070679, 43.8006516]
    },
    {
      "date": "2006-09-17T04:00:00.000Z",
      "location": [-79.4291306, 43.6385841]
    },
    {
      "date": "2006-09-25T04:00:00.000Z",
      "location": [-79.5691071, 43.7590561]
    },
    {
      "date": "2006-10-09T04:00:00.000Z",
      "location": [-79.3044662, 43.7189102]
    },
    {
      "date": "2006-10-27T04:00:00.000Z",
      "location": [-79.3866196, 43.6486397]
    },
    {
      "date": "2006-11-14T05:00:00.000Z",
      "location": [-79.2286911, 43.809391]
    },
    {
      "date": "2006-12-03T05:00:00.000Z",
      "location": [-79.3939285, 43.6427383]
    },
    {
      "date": "2006-12-25T05:00:00.000Z",
      "location": [-79.4273758, 43.6389618]
    },
    {
      "date": "2006-12-27T05:00:00.000Z",
      "location": [-79.5188217, 43.7619209]
    },
    {
      "date": "2007-01-01T05:00:00.000Z",
      "location": [-79.2282181, 43.7999954]
    },
    {
      "date": "2007-01-11T05:00:00.000Z",
      "location": [-79.3244934, 43.712368]
    },
    {
      "date": "2007-01-14T05:00:00.000Z",
      "location": [-79.2683182, 43.782032]
    },
    {
      "date": "2007-02-04T05:00:00.000Z",
      "location": [-79.2279053, 43.7224388]
    },
    {
      "date": "2007-03-15T04:00:00.000Z",
      "location": [-79.5483704, 43.7487793]
    },
    {
      "date": "2007-03-17T04:00:00.000Z",
      "location": [-79.4408417, 43.6926575]
    },
    {
      "date": "2007-04-04T04:00:00.000Z",
      "location": [-79.3695602, 43.6681747]
    },
    {
      "date": "2007-04-05T04:00:00.000Z",
      "location": [-79.5870972, 43.7536201]
    },
    {
      "date": "2007-04-12T04:00:00.000Z",
      "location": [-79.3543396, 43.6650505]
    },
    {
      "date": "2007-04-21T04:00:00.000Z",
      "location": [-79.5276794, 43.7166977]
    },
    {
      "date": "2007-05-09T04:00:00.000Z",
      "location": [-79.1794968, 43.8042831]
    },
    {
      "date": "2007-05-18T04:00:00.000Z",
      "location": [-79.3901978, 43.7084122]
    },
    {
      "date": "2007-05-21T04:00:00.000Z",
      "location": [-79.5401306, 43.7417984]
    },
    {
      "date": "2007-05-23T04:00:00.000Z",
      "location": [-79.3495636, 43.7518005]
    },
    {
      "date": "2007-05-20T04:00:00.000Z",
      "location": [-79.4013138, 43.6496735]
    },
    {
      "date": "2007-06-09T04:00:00.000Z",
      "location": [-79.5783081, 43.735508]
    },
    {
      "date": "2007-07-04T04:00:00.000Z",
      "location": [-79.1538544, 43.7976494]
    },
    {
      "date": "2007-07-03T04:00:00.000Z",
      "location": [-79.4493713, 43.7208595]
    },
    {
      "date": "2007-07-21T04:00:00.000Z",
      "location": [-79.4406357, 43.6922646]
    },
    {
      "date": "2007-07-22T04:00:00.000Z",
      "location": [-79.5034027, 43.7410774]
    },
    {
      "date": "2007-07-22T04:00:00.000Z",
      "location": [-79.3744507, 43.6688728]
    },
    {
      "date": "2007-07-22T04:00:00.000Z",
      "location": [-79.3886795, 43.648201]
    },
    {
      "date": "2007-07-28T04:00:00.000Z",
      "location": [-79.2119064, 43.8184433]
    },
    {
      "date": "2007-07-30T04:00:00.000Z",
      "location": [-79.5935364, 43.7337761]
    },
    {
      "date": "2007-08-04T04:00:00.000Z",
      "location": [-79.3623352, 43.6629715]
    },
    {
      "date": "2007-08-26T04:00:00.000Z",
      "location": [-79.2840805, 43.6939468]
    },
    {
      "date": "2007-08-16T04:00:00.000Z",
      "location": [-79.520401, 43.7688293]
    },
    {
      "date": "2007-09-19T04:00:00.000Z",
      "location": [-79.3821182, 43.6590996]
    },
    {
      "date": "2007-09-28T04:00:00.000Z",
      "location": [-79.5170746, 43.7548561]
    },
    {
      "date": "2007-10-06T04:00:00.000Z",
      "location": [-79.4937515, 43.6716728]
    },
    {
      "date": "2007-10-08T04:00:00.000Z",
      "location": [-79.4312897, 43.6805267]
    },
    {
      "date": "2007-10-09T04:00:00.000Z",
      "location": [-79.5113907, 43.7501373]
    },
    {
      "date": "2007-10-14T04:00:00.000Z",
      "location": [-79.3101501, 43.7570267]
    },
    {
      "date": "2007-10-21T04:00:00.000Z",
      "location": [-79.3529129, 43.6661606]
    },
    {
      "date": "2007-10-27T04:00:00.000Z",
      "location": [-79.5305557, 43.6467743]
    },
    {
      "date": "2007-11-06T05:00:00.000Z",
      "location": [-79.366951, 43.6565018]
    },
    {
      "date": "2008-02-01T05:00:00.000Z",
      "location": [-79.2447205, 43.7447929]
    },
    {
      "date": "2007-11-23T05:00:00.000Z",
      "location": [-79.2993469, 43.783596]
    },
    {
      "date": "2007-11-23T05:00:00.000Z",
      "location": [-79.2349319, 43.7946625]
    },
    {
      "date": "2007-11-25T05:00:00.000Z",
      "location": [-79.5147705, 43.7672119]
    },
    {
      "date": "2007-11-28T05:00:00.000Z",
      "location": [-79.1954346, 43.784832]
    },
    {
      "date": "2007-12-02T05:00:00.000Z",
      "location": [-79.2125473, 43.812088]
    },
    {
      "date": "2007-12-14T05:00:00.000Z",
      "location": [-79.2110214, 43.8179092]
    },
    {
      "date": "2007-12-23T05:00:00.000Z",
      "location": [-79.3595047, 43.6583023]
    },
    {
      "date": "2008-01-12T05:00:00.000Z",
      "location": [-79.3864212, 43.6693687]
    },
    {
      "date": "2008-01-17T05:00:00.000Z",
      "location": [-79.3494568, 43.666153]
    },
    {
      "date": "2008-01-22T05:00:00.000Z",
      "location": [-79.5096054, 43.7248955]
    },
    {
      "date": "2008-02-01T05:00:00.000Z",
      "location": [-79.2447205, 43.7447929]
    },
    {
      "date": "2013-02-11T05:00:00.000Z",
      "location": [-79.5168915, 43.7518196]
    },
    {
      "date": "2013-02-10T05:00:00.000Z",
      "location": [-79.4249268, 43.6326866]
    },
    {
      "date": "2013-02-17T05:00:00.000Z",
      "location": [-79.5864182, 43.7325096]
    },
    {
      "date": "2013-03-21T04:00:00.000Z",
      "location": [-79.3721619, 43.6633911]
    },
    {
      "date": "2013-03-28T04:00:00.000Z",
      "location": [-79.4000931, 43.644455]
    },
    {
      "date": "2013-03-30T04:00:00.000Z",
      "location": [-79.4570847, 43.7247505]
    },
    {
      "date": "2013-04-25T04:00:00.000Z",
      "location": [-79.2150726, 43.8187714]
    },
    {
      "date": "2013-05-19T04:00:00.000Z",
      "location": [-79.4213791, 43.650959]
    },
    {
      "date": "2013-05-29T04:00:00.000Z",
      "location": [-79.2203674, 43.821373]
    },
    {
      "date": "2013-08-05T04:00:00.000Z",
      "location": [-79.4706421, 43.6378784]
    },
    {
      "date": "2013-08-12T04:00:00.000Z",
      "location": [-79.5457153, 43.7212753]
    },
    {
      "date": "2013-07-28T04:00:00.000Z",
      "location": [-79.5160751, 43.7507401]
    },
    {
      "date": "2013-08-23T04:00:00.000Z",
      "location": [-79.5104523, 43.7492294]
    },
    {
      "date": "2013-08-23T04:00:00.000Z",
      "location": [-79.5104523, 43.7492294]
    },
    {
      "date": "2014-01-29T05:00:00.000Z",
      "location": [-79.5173416, 43.6946144]
    },
    {
      "date": "2014-02-04T05:00:00.000Z",
      "location": [-79.4043961, 43.7336693]
    },
    {
      "date": "2014-02-09T05:00:00.000Z",
      "location": [-79.5529938, 43.6088715]
    },
    {
      "date": "2014-03-08T05:00:00.000Z",
      "location": [-79.3373184, 43.7165565]
    },
    {
      "date": "2013-09-03T04:00:00.000Z",
      "location": [-79.5283203, 43.7045479]
    },
    {
      "date": "2013-09-17T04:00:00.000Z",
      "location": [-79.5704117, 43.676342]
    },
    {
      "date": "2014-03-19T04:00:00.000Z",
      "location": [-79.3106689, 43.6861649]
    },
    {
      "date": "2014-03-19T04:00:00.000Z",
      "location": [-79.3106689, 43.6861649]
    },
    {
      "date": "2014-03-25T04:00:00.000Z",
      "location": [-79.4523697, 43.6684875]
    },
    {
      "date": "2014-04-04T04:00:00.000Z",
      "location": [-79.3680573, 43.6644669]
    },
    {
      "date": "2014-04-12T04:00:00.000Z",
      "location": [-79.2130203, 43.8190956]
    },
    {
      "date": "2014-05-02T04:00:00.000Z",
      "location": [-79.4473343, 43.6963615]
    },
    {
      "date": "2014-05-24T04:00:00.000Z",
      "location": [-79.3282013, 43.6743202]
    },
    {
      "date": "2014-07-06T04:00:00.000Z",
      "location": [-79.3734589, 43.6573067]
    },
    {
      "date": "2014-07-08T04:00:00.000Z",
      "location": [-79.4482269, 43.7191162]
    },
    {
      "date": "2014-08-18T04:00:00.000Z",
      "location": [-79.4988251, 43.6844788]
    },
    {
      "date": "2014-08-26T04:00:00.000Z",
      "location": [-79.3944855, 43.6440315]
    },
    {
      "date": "2014-09-08T04:00:00.000Z",
      "location": [-79.5038376, 43.7028809]
    },
    {
      "date": "2014-09-11T04:00:00.000Z",
      "location": [-79.3953781, 43.7600479]
    },
    {
      "date": "2014-09-14T04:00:00.000Z",
      "location": [-79.2247086, 43.757843]
    },
    {
      "date": "2014-09-17T04:00:00.000Z",
      "location": [-79.5005188, 43.7622452]
    },
    {
      "date": "2014-09-29T04:00:00.000Z",
      "location": [-79.2205582, 43.742836]
    },
    {
      "date": "2014-10-05T04:00:00.000Z",
      "location": [-79.4740753, 43.6789017]
    },
    {
      "date": "2014-10-06T04:00:00.000Z",
      "location": [-79.5455322, 43.700695]
    },
    {
      "date": "2014-10-06T04:00:00.000Z",
      "location": [-79.5455322, 43.700695]
    },
    {
      "date": "2014-10-06T04:00:00.000Z",
      "location": [-79.3637543, 43.6572227]
    },
    {
      "date": "2006-07-12T04:00:00.000Z",
      "location": [-79.2264557, 43.8298149]
    },
    {
      "date": "2014-10-30T04:00:00.000Z",
      "location": [-79.4860001, 43.7428703]
    },
    {
      "date": "2014-11-06T05:00:00.000Z",
      "location": [-79.4715118, 43.690979]
    },
    {
      "date": "2013-09-23T04:00:00.000Z",
      "location": [-79.366951, 43.6565018]
    },
    {
      "date": "2013-11-10T05:00:00.000Z",
      "location": [-79.298027, 43.6788864]
    },
    {
      "date": "2008-02-17T05:00:00.000Z",
      "location": [-79.2305603, 43.8135796]
    },
    {
      "date": "2008-02-22T05:00:00.000Z",
      "location": [-79.2114182, 43.7637329]
    },
    {
      "date": "2013-02-24T05:00:00.000Z",
      "location": [-79.542305, 43.7684555]
    },
    {
      "date": "2013-06-16T04:00:00.000Z",
      "location": [-79.4108047, 43.7697296]
    },
    {
      "date": "2008-03-14T04:00:00.000Z",
      "location": [-79.4482269, 43.7191162]
    },
    {
      "date": "2013-11-24T05:00:00.000Z",
      "location": [-79.5858307, 43.7509766]
    },
    {
      "date": "2008-03-18T04:00:00.000Z",
      "location": [-79.3416061, 43.6648445]
    },
    {
      "date": "2009-08-04T04:00:00.000Z",
      "location": [-79.4945831, 43.6104546]
    },
    {
      "date": "2009-08-05T04:00:00.000Z",
      "location": [-79.2531891, 43.7355843]
    },
    {
      "date": "2009-08-10T04:00:00.000Z",
      "location": [-79.5930023, 43.73806]
    },
    {
      "date": "2009-08-16T04:00:00.000Z",
      "location": [-79.5881653, 43.7463188]
    },
    {
      "date": "2014-11-16T05:00:00.000Z",
      "location": [-79.3860626, 43.6552429]
    },
    {
      "date": "2009-09-02T04:00:00.000Z",
      "location": [-79.3381882, 43.672451]
    },
    {
      "date": "2009-09-05T04:00:00.000Z",
      "location": [-79.3678894, 43.6503944]
    },
    {
      "date": "2009-09-07T04:00:00.000Z",
      "location": [-79.3691254, 43.7912979]
    },
    {
      "date": "2008-05-28T04:00:00.000Z",
      "location": [-79.2139359, 43.8105507]
    },
    {
      "date": "2008-05-28T04:00:00.000Z",
      "location": [-79.2971649, 43.7841148]
    },
    {
      "date": "2009-10-10T04:00:00.000Z",
      "location": [-79.4347763, 43.6339531]
    },
    {
      "date": "2008-05-30T04:00:00.000Z",
      "location": [-79.2992859, 43.7166748]
    },
    {
      "date": "2008-02-25T05:00:00.000Z",
      "location": [-79.3063965, 43.6859818]
    },
    {
      "date": "2009-10-18T04:00:00.000Z",
      "location": [-79.4875946, 43.7496109]
    },
    {
      "date": "2015-01-27T05:00:00.000Z",
      "location": [-79.4493713, 43.7208595]
    },
    {
      "date": "2008-06-13T04:00:00.000Z",
      "location": [-79.4094772, 43.6450157]
    },
    {
      "date": "2008-06-13T04:00:00.000Z",
      "location": [-79.4094772, 43.6450157]
    },
    {
      "date": "2008-06-15T04:00:00.000Z",
      "location": [-79.4937515, 43.6716728]
    },
    {
      "date": "2008-07-09T04:00:00.000Z",
      "location": [-79.3130264, 43.7514992]
    },
    {
      "date": "2008-07-17T04:00:00.000Z",
      "location": [-79.1954346, 43.784832]
    },
    {
      "date": "2008-07-19T04:00:00.000Z",
      "location": [-79.1527786, 43.797863]
    },
    {
      "date": "2008-07-20T04:00:00.000Z",
      "location": [-79.5459824, 43.6062927]
    },
    {
      "date": "2008-07-20T04:00:00.000Z",
      "location": [-79.5459824, 43.6062927]
    },
    {
      "date": "2008-07-20T04:00:00.000Z",
      "location": [-79.5459824, 43.6062927]
    },
    {
      "date": "2007-07-04T04:00:00.000Z",
      "location": [-79.1538544, 43.7976494]
    },
    {
      "date": "2008-07-26T04:00:00.000Z",
      "location": [-79.5105591, 43.7147408]
    },
    {
      "date": "2008-07-27T04:00:00.000Z",
      "location": [-79.4771805, 43.6896706]
    },
    {
      "date": "2008-08-10T04:00:00.000Z",
      "location": [-79.2572784, 43.7873955]
    },
    {
      "date": "2008-08-24T04:00:00.000Z",
      "location": [-79.4409332, 43.7654686]
    },
    {
      "date": "2008-08-26T04:00:00.000Z",
      "location": [-79.2259445, 43.8065643]
    },
    {
      "date": "2008-09-08T04:00:00.000Z",
      "location": [-79.4697189, 43.6913643]
    },
    {
      "date": "2008-09-09T04:00:00.000Z",
      "location": [-79.4812698, 43.7225571]
    },
    {
      "date": "2008-09-16T04:00:00.000Z",
      "location": [-79.5188217, 43.7619209]
    },
    {
      "date": "2008-09-22T04:00:00.000Z",
      "location": [-79.5141907, 43.7554741]
    },
    {
      "date": "2008-10-03T04:00:00.000Z",
      "location": [-79.5114594, 43.6839638]
    },
    {
      "date": "2008-10-09T04:00:00.000Z",
      "location": [-79.5138016, 43.7198334]
    },
    {
      "date": "2008-10-14T04:00:00.000Z",
      "location": [-79.2251663, 43.7417946]
    },
    {
      "date": "2008-10-25T04:00:00.000Z",
      "location": [-79.3302231, 43.6632423]
    },
    {
      "date": "2008-10-25T04:00:00.000Z",
      "location": [-79.4401245, 43.754097]
    },
    {
      "date": "2008-11-26T05:00:00.000Z",
      "location": [-79.3097916, 43.7421455]
    },
    {
      "date": "2009-01-03T05:00:00.000Z",
      "location": [-79.5529861, 43.751442]
    },
    {
      "date": "2009-01-04T05:00:00.000Z",
      "location": [-79.355278, 43.7409439]
    },
    {
      "date": "2009-01-20T05:00:00.000Z",
      "location": [-79.4743576, 43.6885223]
    },
    {
      "date": "2009-02-01T05:00:00.000Z",
      "location": [-79.5113144, 43.6983452]
    },
    {
      "date": "2009-02-10T05:00:00.000Z",
      "location": [-79.2057037, 43.7856903]
    },
    {
      "date": "2009-02-22T05:00:00.000Z",
      "location": [-79.511673, 43.7660446]
    },
    {
      "date": "2009-02-26T05:00:00.000Z",
      "location": [-79.4771805, 43.6896706]
    },
    {
      "date": "2009-02-28T05:00:00.000Z",
      "location": [-79.2762833, 43.753231]
    },
    {
      "date": "2009-04-21T04:00:00.000Z",
      "location": [-79.4724197, 43.6839981]
    },
    {
      "date": "2009-04-22T04:00:00.000Z",
      "location": [-79.4988251, 43.6844788]
    },
    {
      "date": "2009-05-01T04:00:00.000Z",
      "location": [-79.5034866, 43.6638908]
    },
    {
      "date": "2009-05-11T04:00:00.000Z",
      "location": [-79.5004578, 43.6680069]
    },
    {
      "date": "2009-07-19T04:00:00.000Z",
      "location": [-79.1280975, 43.7938194]
    },
    {
      "date": "2009-07-25T04:00:00.000Z",
      "location": [-79.4074707, 43.6659164]
    },
    {
      "date": "2009-08-01T04:00:00.000Z",
      "location": [-79.3954315, 43.6719017]
    },
    {
      "date": "2009-08-02T04:00:00.000Z",
      "location": [-79.606369, 43.7550163]
    },
    {
      "date": "2009-10-30T04:00:00.000Z",
      "location": [-79.431488, 43.6746826]
    },
    {
      "date": "2017-11-21T05:00:00.000Z",
      "location": [-79.2017746, 43.813343]
    },
    {
      "date": "2015-01-31T05:00:00.000Z",
      "location": [-79.4060211, 43.6523132]
    },
    {
      "date": "2009-11-02T05:00:00.000Z",
      "location": [-79.3463745, 43.679409]
    },
    {
      "date": "2017-12-04T05:00:00.000Z",
      "location": [-79.2251663, 43.7417946]
    },
    {
      "date": "2009-11-03T05:00:00.000Z",
      "location": [-79.4966965, 43.7519417]
    },
    {
      "date": "2015-02-28T05:00:00.000Z",
      "location": [-79.3208923, 43.6839447]
    },
    {
      "date": "2015-02-28T05:00:00.000Z",
      "location": [-79.3208923, 43.6839447]
    },
    {
      "date": "2015-03-17T04:00:00.000Z",
      "location": [-79.5486755, 43.7013283]
    },
    {
      "date": "2015-03-30T04:00:00.000Z",
      "location": [-79.5864182, 43.7325096]
    },
    {
      "date": "2017-11-13T05:00:00.000Z",
      "location": [-79.3660126, 43.6594429]
    },
    {
      "date": "2009-11-11T05:00:00.000Z",
      "location": [-79.4389038, 43.6915436]
    },
    {
      "date": "2017-12-16T05:00:00.000Z",
      "location": [-79.3707886, 43.6478386]
    },
    {
      "date": "2009-11-17T05:00:00.000Z",
      "location": [-79.5506363, 43.6882286]
    },
    {
      "date": "2015-05-19T04:00:00.000Z",
      "location": [-79.3247681, 43.6831207]
    },
    {
      "date": "2009-11-22T05:00:00.000Z",
      "location": [-79.3877258, 43.6656456]
    },
    {
      "date": "2018-01-09T05:00:00.000Z",
      "location": [-79.5173416, 43.6946144]
    },
    {
      "date": "2009-11-22T05:00:00.000Z",
      "location": [-79.2665024, 43.7689438]
    },
    {
      "date": "2016-06-03T04:00:00.000Z",
      "location": [-79.5403366, 43.7427292]
    },
    {
      "date": "2015-05-20T04:00:00.000Z",
      "location": [-79.4477463, 43.6597824]
    },
    {
      "date": "2009-12-03T05:00:00.000Z",
      "location": [-79.5047607, 43.715744]
    },
    {
      "date": "2016-06-07T04:00:00.000Z",
      "location": [-79.3967972, 43.7045708]
    },
    {
      "date": "2018-01-19T05:00:00.000Z",
      "location": [-79.5684204, 43.6594009]
    },
    {
      "date": "2016-07-01T04:00:00.000Z",
      "location": [-79.4040756, 43.6572113]
    },
    {
      "date": "2018-01-19T05:00:00.000Z",
      "location": [-79.5684204, 43.6594009]
    },
    {
      "date": "2015-06-01T04:00:00.000Z",
      "location": [-79.2352676, 43.7577248]
    },
    {
      "date": "2009-12-06T05:00:00.000Z",
      "location": [-79.5694427, 43.7566948]
    },
    {
      "date": "2016-07-01T04:00:00.000Z",
      "location": [-79.4040756, 43.6572113]
    },
    {
      "date": "2009-12-07T05:00:00.000Z",
      "location": [-79.525444, 43.7030106]
    },
    {
      "date": "2018-02-08T05:00:00.000Z",
      "location": [-79.3873901, 43.7807274]
    },
    {
      "date": "2018-02-15T05:00:00.000Z",
      "location": [-79.1724472, 43.7821922]
    },
    {
      "date": "2018-03-02T05:00:00.000Z",
      "location": [-79.520401, 43.7688293]
    },
    {
      "date": "2018-03-10T05:00:00.000Z",
      "location": [-79.5881653, 43.7463188]
    },
    {
      "date": "2018-03-16T04:00:00.000Z",
      "location": [-79.5174942, 43.692627]
    },
    {
      "date": "2018-03-17T04:00:00.000Z",
      "location": [-79.4556808, 43.7182617]
    },
    {
      "date": "2018-03-17T04:00:00.000Z",
      "location": [-79.4556808, 43.7182617]
    },
    {
      "date": "2018-04-06T04:00:00.000Z",
      "location": [-79.318161, 43.7645187]
    },
    {
      "date": "2018-05-07T04:00:00.000Z",
      "location": [-79.5501633, 43.6733971]
    },
    {
      "date": "2018-05-20T04:00:00.000Z",
      "location": [-79.2161942, 43.775425]
    },
    {
      "date": "2018-05-20T04:00:00.000Z",
      "location": [-79.3797302, 43.6473351]
    },
    {
      "date": "2018-05-28T04:00:00.000Z",
      "location": [-79.2242279, 43.8044968]
    },
    {
      "date": "2018-05-28T04:00:00.000Z",
      "location": [-79.3901978, 43.67136]
    },
    {
      "date": "2018-05-30T04:00:00.000Z",
      "location": [-79.380928, 43.6563301]
    },
    {
      "date": "2018-06-05T04:00:00.000Z",
      "location": [-79.5093994, 43.6025887]
    },
    {
      "date": "2018-06-24T04:00:00.000Z",
      "location": [-79.4439087, 43.7194862]
    },
    {
      "date": "2018-06-24T04:00:00.000Z",
      "location": [-79.5764389, 43.7384567]
    },
    {
      "date": "2018-06-24T04:00:00.000Z",
      "location": [-79.5764389, 43.7384567]
    },
    {
      "date": "2018-06-25T04:00:00.000Z",
      "location": [-79.3710327, 43.6582947]
    },
    {
      "date": "2018-06-30T04:00:00.000Z",
      "location": [-79.3936996, 43.649353]
    },
    {
      "date": "2018-06-30T04:00:00.000Z",
      "location": [-79.3936996, 43.649353]
    },
    {
      "date": "2018-07-01T04:00:00.000Z",
      "location": [-79.4023361, 43.6552353]
    },
    {
      "date": "2018-07-08T04:00:00.000Z",
      "location": [-79.5147705, 43.7672119]
    },
    {
      "date": "2018-07-09T04:00:00.000Z",
      "location": [-79.5187607, 43.769165]
    },
    {
      "date": "2018-07-23T04:00:00.000Z",
      "location": [-79.3519669, 43.6775589]
    },
    {
      "date": "2018-07-23T04:00:00.000Z",
      "location": [-79.3519669, 43.6775589]
    },
    {
      "date": "2018-07-25T04:00:00.000Z",
      "location": [-79.5688705, 43.7547112]
    },
    {
      "date": "2018-08-15T04:00:00.000Z",
      "location": [-79.5894241, 43.7320251]
    },
    {
      "date": "2018-08-19T04:00:00.000Z",
      "location": [-79.3662109, 43.6526985]
    },
    {
      "date": "2018-08-22T04:00:00.000Z",
      "location": [-79.3406067, 43.6797943]
    },
    {
      "date": "2018-08-22T04:00:00.000Z",
      "location": [-79.5008926, 43.6913033]
    },
    {
      "date": "2018-08-31T04:00:00.000Z",
      "location": [-79.2755432, 43.7015152]
    },
    {
      "date": "2018-09-02T04:00:00.000Z",
      "location": [-79.2101669, 43.8091469]
    },
    {
      "date": "2018-09-02T04:00:00.000Z",
      "location": [-79.4032898, 43.6357384]
    },
    {
      "date": "2018-09-08T04:00:00.000Z",
      "location": [-79.4631729, 43.674015]
    },
    {
      "date": "2018-09-14T04:00:00.000Z",
      "location": [-79.2326126, 43.7416115]
    },
    {
      "date": "2018-09-18T04:00:00.000Z",
      "location": [-79.2624893, 43.7888451]
    },
    {
      "date": "2018-09-24T04:00:00.000Z",
      "location": [-79.3448181, 43.7726898]
    },
    {
      "date": "2018-09-25T04:00:00.000Z",
      "location": [-79.3634949, 43.6592712]
    },
    {
      "date": "2009-12-09T05:00:00.000Z",
      "location": [-79.5230484, 43.7177048]
    },
    {
      "date": "2010-01-07T05:00:00.000Z",
      "location": [-79.2112122, 43.8118591]
    },
    {
      "date": "2009-12-29T05:00:00.000Z",
      "location": [-79.4789581, 43.6655655]
    },
    {
      "date": "2011-01-05T05:00:00.000Z",
      "location": [-79.2789001, 43.7328644]
    },
    {
      "date": "2016-07-13T04:00:00.000Z",
      "location": [-79.520134, 43.7378082]
    },
    {
      "date": "2016-07-28T04:00:00.000Z",
      "location": [-79.2580719, 43.7529755]
    },
    {
      "date": "2015-06-28T04:00:00.000Z",
      "location": [-79.4237289, 43.6411324]
    },
    {
      "date": "2015-06-28T04:00:00.000Z",
      "location": [-79.4237289, 43.6411324]
    },
    {
      "date": "2010-02-07T05:00:00.000Z",
      "location": [-79.5026093, 43.6984215]
    },
    {
      "date": "2011-02-01T05:00:00.000Z",
      "location": [-79.5490723, 43.7506523]
    },
    {
      "date": "2016-08-21T04:00:00.000Z",
      "location": [-79.4127045, 43.6419144]
    },
    {
      "date": "2015-08-04T04:00:00.000Z",
      "location": [-79.4249268, 43.6326866]
    },
    {
      "date": "2011-02-11T05:00:00.000Z",
      "location": [-79.4832687, 43.6934128]
    },
    {
      "date": "2015-08-04T04:00:00.000Z",
      "location": [-79.4249268, 43.6326866]
    },
    {
      "date": "2011-02-11T05:00:00.000Z",
      "location": [-79.221199, 43.7596092]
    },
    {
      "date": "2011-02-16T05:00:00.000Z",
      "location": [-79.5094604, 43.6973038]
    },
    {
      "date": "2015-08-09T04:00:00.000Z",
      "location": [-79.3832016, 43.6543198]
    },
    {
      "date": "2011-02-26T05:00:00.000Z",
      "location": [-79.2147293, 43.8006172]
    },
    {
      "date": "2016-08-27T04:00:00.000Z",
      "location": [-79.3523102, 43.6600647]
    },
    {
      "date": "2015-08-19T04:00:00.000Z",
      "location": [-79.3710327, 43.6582947]
    },
    {
      "date": "2011-03-02T05:00:00.000Z",
      "location": [-79.501503, 43.6648865]
    },
    {
      "date": "2011-03-20T04:00:00.000Z",
      "location": [-79.5521545, 43.695797]
    },
    {
      "date": "2011-03-28T04:00:00.000Z",
      "location": [-79.2568359, 43.7348099]
    },
    {
      "date": "2011-03-28T04:00:00.000Z",
      "location": [-79.3125916, 43.6672478]
    },
    {
      "date": "2011-04-30T04:00:00.000Z",
      "location": [-79.5596924, 43.7133217]
    },
    {
      "date": "2011-05-26T04:00:00.000Z",
      "location": [-79.3314133, 43.7931099]
    },
    {
      "date": "2010-02-08T05:00:00.000Z",
      "location": [-79.5047607, 43.715744]
    },
    {
      "date": "2011-05-29T04:00:00.000Z",
      "location": [-79.5928192, 43.7390823]
    },
    {
      "date": "2011-06-16T04:00:00.000Z",
      "location": [-79.5164871, 43.7525101]
    },
    {
      "date": "2011-07-29T04:00:00.000Z",
      "location": [-79.5632248, 43.6526527]
    },
    {
      "date": "2011-07-31T04:00:00.000Z",
      "location": [-79.5079803, 43.6912079]
    },
    {
      "date": "2011-08-20T04:00:00.000Z",
      "location": [-79.4942398, 43.7431068]
    },
    {
      "date": "2011-08-23T04:00:00.000Z",
      "location": [-79.4364471, 43.7412224]
    },
    {
      "date": "2011-08-27T04:00:00.000Z",
      "location": [-79.501503, 43.6648865]
    },
    {
      "date": "2011-09-17T04:00:00.000Z",
      "location": [-79.2681274, 43.7070007]
    },
    {
      "date": "2011-10-02T04:00:00.000Z",
      "location": [-79.4159393, 43.6471863]
    },
    {
      "date": "2010-03-11T05:00:00.000Z",
      "location": [-79.1994705, 43.7654495]
    },
    {
      "date": "2011-10-09T04:00:00.000Z",
      "location": [-79.2992859, 43.7166748]
    },
    {
      "date": "2011-10-14T04:00:00.000Z",
      "location": [-79.3006058, 43.6943817]
    },
    {
      "date": "2011-10-15T04:00:00.000Z",
      "location": [-79.5098801, 43.7634354]
    },
    {
      "date": "2011-10-21T04:00:00.000Z",
      "location": [-79.5761642, 43.6485023]
    },
    {
      "date": "2011-10-28T04:00:00.000Z",
      "location": [-79.5086136, 43.7300453]
    },
    {
      "date": "2011-11-29T05:00:00.000Z",
      "location": [-79.3229218, 43.6523476]
    },
    {
      "date": "2011-12-30T05:00:00.000Z",
      "location": [-79.2056274, 43.7640533]
    },
    {
      "date": "2010-05-03T04:00:00.000Z",
      "location": [-79.4337769, 43.684906]
    },
    {
      "date": "2010-05-17T04:00:00.000Z",
      "location": [-79.4703979, 43.691227]
    },
    {
      "date": "2012-01-01T05:00:00.000Z",
      "location": [-79.2827606, 43.7279472]
    },
    {
      "date": "2012-01-21T05:00:00.000Z",
      "location": [-79.2778625, 43.7299652]
    },
    {
      "date": "2012-01-24T05:00:00.000Z",
      "location": [-79.2209244, 43.8055801]
    },
    {
      "date": "2016-09-11T04:00:00.000Z",
      "location": [-79.4253387, 43.6373482]
    },
    {
      "date": "2016-09-30T04:00:00.000Z",
      "location": [-79.6099167, 43.7341003]
    },
    {
      "date": "2016-10-16T04:00:00.000Z",
      "location": [-79.5177917, 43.7006035]
    },
    {
      "date": "2015-08-19T04:00:00.000Z",
      "location": [-79.5041122, 43.714138]
    },
    {
      "date": "2016-10-18T04:00:00.000Z",
      "location": [-79.6001587, 43.7470398]
    },
    {
      "date": "2015-07-09T04:00:00.000Z",
      "location": [-79.5864182, 43.7325096]
    },
    {
      "date": "2016-10-24T04:00:00.000Z",
      "location": [-79.5563736, 43.6995316]
    },
    {
      "date": "2016-10-27T04:00:00.000Z",
      "location": [-79.2056274, 43.7640533]
    },
    {
      "date": "2016-11-18T05:00:00.000Z",
      "location": [-79.3135223, 43.7260284]
    },
    {
      "date": "2015-10-01T04:00:00.000Z",
      "location": [-79.1568756, 43.7921066]
    },
    {
      "date": "2016-11-19T05:00:00.000Z",
      "location": [-79.5187607, 43.769165]
    },
    {
      "date": "2015-10-17T04:00:00.000Z",
      "location": [-79.5955658, 43.6867599]
    },
    {
      "date": "2016-11-27T05:00:00.000Z",
      "location": [-79.3529434, 43.6617737]
    },
    {
      "date": "2015-10-31T04:00:00.000Z",
      "location": [-79.386116, 43.6686325]
    },
    {
      "date": "2015-11-04T05:00:00.000Z",
      "location": [-79.3592606, 43.717308]
    },
    {
      "date": "2016-12-07T05:00:00.000Z",
      "location": [-79.3427277, 43.6937408]
    },
    {
      "date": "2016-12-14T05:00:00.000Z",
      "location": [-79.219696, 43.7450218]
    },
    {
      "date": "2016-12-17T05:00:00.000Z",
      "location": [-79.5162048, 43.700161]
    },
    {
      "date": "2015-11-17T05:00:00.000Z",
      "location": [-79.6040955, 43.7318459]
    },
    {
      "date": "2016-12-18T05:00:00.000Z",
      "location": [-79.5486755, 43.7013283]
    },
    {
      "date": "2015-11-30T05:00:00.000Z",
      "location": [-79.5821152, 43.7432671]
    },
    {
      "date": "2015-12-11T05:00:00.000Z",
      "location": [-79.3939362, 43.6465149]
    },
    {
      "date": "2015-12-11T05:00:00.000Z",
      "location": [-79.341011, 43.7762108]
    },
    {
      "date": "2015-12-15T05:00:00.000Z",
      "location": [-79.5720749, 43.7559776]
    },
    {
      "date": "2016-01-04T05:00:00.000Z",
      "location": [-79.2912979, 43.6931458]
    },
    {
      "date": "2016-01-20T05:00:00.000Z",
      "location": [-79.5434952, 43.6719971]
    },
    {
      "date": "2016-01-20T05:00:00.000Z",
      "location": [-79.3331299, 43.7981796]
    },
    {
      "date": "2016-01-19T05:00:00.000Z",
      "location": [-79.5097122, 43.5997925]
    },
    {
      "date": "2016-01-24T05:00:00.000Z",
      "location": [-79.2536545, 43.7716827]
    },
    {
      "date": "2016-01-29T05:00:00.000Z",
      "location": [-79.4658127, 43.7082405]
    },
    {
      "date": "2016-01-31T05:00:00.000Z",
      "location": [-79.399231, 43.6558533]
    },
    {
      "date": "2016-01-31T05:00:00.000Z",
      "location": [-79.399231, 43.6558533]
    },
    {
      "date": "2016-02-28T05:00:00.000Z",
      "location": [-79.5174942, 43.692627]
    },
    {
      "date": "2016-02-29T05:00:00.000Z",
      "location": [-79.219696, 43.7450218]
    },
    {
      "date": "2016-03-20T04:00:00.000Z",
      "location": [-79.3051605, 43.7971191]
    },
    {
      "date": "2016-03-23T04:00:00.000Z",
      "location": [-79.3405991, 43.6622849]
    },
    {
      "date": "2016-04-02T04:00:00.000Z",
      "location": [-79.3644638, 43.6556168]
    },
    {
      "date": "2016-04-13T04:00:00.000Z",
      "location": [-79.3867874, 43.6702271]
    },
    {
      "date": "2016-04-14T04:00:00.000Z",
      "location": [-79.4965363, 43.6117668]
    },
    {
      "date": "2016-04-17T04:00:00.000Z",
      "location": [-79.3247681, 43.6831207]
    },
    {
      "date": "2016-04-29T04:00:00.000Z",
      "location": [-79.3129272, 43.7584152]
    },
    {
      "date": "2016-04-29T04:00:00.000Z",
      "location": [-79.3129272, 43.7584152]
    },
    {
      "date": "2016-05-15T04:00:00.000Z",
      "location": [-79.5894241, 43.7320251]
    },
    {
      "date": "2017-01-08T05:00:00.000Z",
      "location": [-79.3679428, 43.6548576]
    },
    {
      "date": "2017-01-23T05:00:00.000Z",
      "location": [-79.5704193, 43.7559853]
    },
    {
      "date": "2017-01-30T05:00:00.000Z",
      "location": [-79.3710785, 43.6516075]
    },
    {
      "date": "2017-01-30T05:00:00.000Z",
      "location": [-79.3623352, 43.6629715]
    },
    {
      "date": "2017-02-01T05:00:00.000Z",
      "location": [-79.5170746, 43.7548561]
    },
    {
      "date": "2017-02-05T05:00:00.000Z",
      "location": [-79.3429718, 43.6828117]
    },
    {
      "date": "2017-02-06T05:00:00.000Z",
      "location": [-79.5523682, 43.7165527]
    },
    {
      "date": "2017-02-25T05:00:00.000Z",
      "location": [-79.3436279, 43.7074776]
    },
    {
      "date": "2016-05-15T04:00:00.000Z",
      "location": [-79.5894241, 43.7320251]
    },
    {
      "date": "2017-03-16T04:00:00.000Z",
      "location": [-79.1846695, 43.8102036]
    },
    {
      "date": "2017-03-18T04:00:00.000Z",
      "location": [-79.2954559, 43.7016792]
    },
    {
      "date": "2017-03-31T04:00:00.000Z",
      "location": [-79.519722, 43.6912231]
    },
    {
      "date": "2017-04-14T04:00:00.000Z",
      "location": [-79.3309326, 43.7974548]
    },
    {
      "date": "2017-04-14T04:00:00.000Z",
      "location": [-79.3444595, 43.6600876]
    },
    {
      "date": "2017-04-21T04:00:00.000Z",
      "location": [-79.4511414, 43.6979179]
    },
    {
      "date": "2017-05-22T04:00:00.000Z",
      "location": [-79.345192, 43.6670952]
    },
    {
      "date": "2017-06-11T04:00:00.000Z",
      "location": [-79.5572815, 43.7476883]
    },
    {
      "date": "2017-07-02T04:00:00.000Z",
      "location": [-79.364769, 43.66045]
    },
    {
      "date": "2017-07-23T04:00:00.000Z",
      "location": [-79.1991959, 43.8133163]
    },
    {
      "date": "2017-07-23T04:00:00.000Z",
      "location": [-79.1991959, 43.8133163]
    },
    {
      "date": "2017-07-23T04:00:00.000Z",
      "location": [-79.2105103, 43.810276]
    },
    {
      "date": "2017-07-30T04:00:00.000Z",
      "location": [-79.4547501, 43.7140884]
    },
    {
      "date": "2017-07-31T04:00:00.000Z",
      "location": [-79.3694611, 43.6545258]
    },
    {
      "date": "2017-08-01T04:00:00.000Z",
      "location": [-79.4208679, 43.6861954]
    },
    {
      "date": "2017-08-20T04:00:00.000Z",
      "location": [-79.406517, 43.656723]
    },
    {
      "date": "2017-08-31T04:00:00.000Z",
      "location": [-79.5138016, 43.7198334]
    },
    {
      "date": "2017-09-02T04:00:00.000Z",
      "location": [-79.5495377, 43.7276535]
    },
    {
      "date": "2017-09-14T04:00:00.000Z",
      "location": [-79.285553, 43.7689018]
    },
    {
      "date": "2017-09-16T04:00:00.000Z",
      "location": [-79.3603287, 43.6634293]
    },
    {
      "date": "2017-09-16T04:00:00.000Z",
      "location": [-79.3863678, 43.6480103]
    },
    {
      "date": "2017-09-29T04:00:00.000Z",
      "location": [-79.5138016, 43.7198334]
    },
    {
      "date": "2017-10-01T04:00:00.000Z",
      "location": [-79.3506165, 43.6430931]
    },
    {
      "date": "2017-10-01T04:00:00.000Z",
      "location": [-79.3506165, 43.6430931]
    },
    {
      "date": "2017-10-05T04:00:00.000Z",
      "location": [-79.5484695, 43.6944733]
    },
    {
      "date": "2017-10-08T04:00:00.000Z",
      "location": [-79.5522308, 43.6987343]
    },
    {
      "date": "2017-10-14T04:00:00.000Z",
      "location": [-79.5529861, 43.751442]
    },
    {
      "date": "2018-10-02T04:00:00.000Z",
      "location": [-79.4946518, 43.7777214]
    },
    {
      "date": "2018-10-03T04:00:00.000Z",
      "location": [-79.2993469, 43.783596]
    },
    {
      "date": "2018-10-11T04:00:00.000Z",
      "location": [-79.4890976, 43.7348633]
    },
    {
      "date": "2018-11-14T05:00:00.000Z",
      "location": [-79.5304413, 43.7194748]
    },
    {
      "date": "2018-11-18T05:00:00.000Z",
      "location": [-79.1896133, 43.7676544]
    },
    {
      "date": "2018-12-12T05:00:00.000Z",
      "location": [-79.5486755, 43.7013283]
    },
    {
      "date": "2018-12-18T05:00:00.000Z",
      "location": [-79.5177765, 43.7590179]
    },
    {
      "date": "2018-12-19T05:00:00.000Z",
      "location": [-79.397644, 43.6449547]
    },
    {
      "date": "2018-12-21T05:00:00.000Z",
      "location": [-79.5858307, 43.7509766]
    }
  ],
};
