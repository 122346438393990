/**
 * Module to handle routing views based on URLs.
 *
 * @module router
 */
var router = {

  /**
   * Method to handle router initialization.
   *
   * @method router.init
   */
  init: function() {

    // Handle the hash change event
    window.onhashchange = function(event) {
      router.route();
    };

    // Set the initial view
    router.route();

  },

  /**
   * Method to handle routing paths for views.
   *
   * @method router.route
   */
  route: function(path) {

    // If no path is provided, use the URL hash
    path = path || window.location.hash.replace("#", "");

    // Handle an offline connection
    if (!window.navigator.onLine) {
      return router.update("offline");
    }

    // Set the view based on the path
    // TODO: Handle URL path params with regex
    if (config.views[path]) {
      app.state.view = app.views[config.views[path]];
    } else {
      app.state.view = app.views[config.views["/"]];
    }

    // Initialize the view's controller
    app.state.view.init();

  },

  /**
   * Method to handle updating the view based on the path.
   *
   * @method router.update
   */
  update: function(view) {

    // If no view is provided, use the one set in the application state
    view = view || app.state.view;

    // Set the template based on the view
    var template = "template-view-" + view.name;
    var element = document.getElementById(template);

    // If there is no template, return
    if (!element) return;

    // Update the DOM with the template contents
    app.ui.root.innerHTML = element.innerHTML;

  },

};
