/**
 * Module to handle math utility helpers.
 *
 * @module math
 */
var math = {

  /**
   * Method to handle calculating the distance between two coordinates.
   *
   * @see http://www.movable-type.co.uk/scripts/latlong.html
   * @see https://en.wikipedia.org/wiki/Haversine_formula
   *
   * @method math.getDistanceBetweenCoords
   */
  getDistanceBetweenCoords: function(start, end, round) {

    var radius = 6371; // Earth's radius in kilometres (roughly)
    var latitude = math.getRadiansFromDegrees(end[1] - start[1]);
    var longitude = math.getRadiansFromDegrees(end[0] - start[0]);

    var value = Math.sin(latitude / 2) *
      Math.sin(latitude / 2) +
      Math.cos(math.getRadiansFromDegrees(start[1])) *
      Math.cos(math.getRadiansFromDegrees(end[1])) *
      Math.sin(longitude / 2) *
      Math.sin(longitude / 2);

    var calculation = 2 * Math.atan2(
      Math.sqrt(value),
      Math.sqrt(1 - value)
    );

    var distance = (radius * calculation) * 1000;

    return round ? Math.round(distance, 1) : distance;

  },

  /**
   * Method to handle converting degress to radians.
   *
   * @see http://www.movable-type.co.uk/scripts/latlong.html
   * @see https://en.wikipedia.org/wiki/Haversine_formula
   *
   * @method math.getRadiansFromDegrees
   */
  getRadiansFromDegrees: function(value) {

    return (Math.PI / 180) * value;

  },

};
